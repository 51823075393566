import React from 'react'
import { IApplicationState } from '../../reducers';
import { Modal, ModalHeader, Form, ModalBody, FormGroup, Input, Label, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ITranslatableContainer, getI18n } from '../../actions/translations';
import { IWebhook, saveWebhook } from '../../actions/webhooks';
import Icon from 'react-fontawesome'
import { withRouter } from 'react-router';


const mapStateToProps = (state : IApplicationState, ownProps: any) => ({
    webhook: state.webhooks.webhooks.find(({ id }) => id === ownProps.match.params.id) || {},
    i18n: getI18n(state),
    processing: state.webhooks.processing
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    saveWebhook
}, dispatch)

interface IBankAccountStatementModalProps extends ITranslatableContainer {
    webhook: IWebhook
    match: any,
    saveWebhook: Function,
    processing: boolean,
    history: any
}

interface IThisState {
    form: IWebhook
}

class BankAccountStatementModal extends React.Component<IBankAccountStatementModalProps, IThisState> {
    form(...args: string[]) : IFormFields {
        return {}
    }

    constructor(props: IBankAccountStatementModalProps) {
        super(props)
        this.state = { form: props.webhook }
        this.form = form_helper.bind(this, this)
    }

    componentWillReceiveProps(nextProps: IBankAccountStatementModalProps) {
        if (nextProps.webhook.id !== this.props.webhook.id) this.setState({ form: nextProps.webhook })
    }

    renderBody() {
        const {
            i18n,
            saveWebhook,
            processing
        } = this.props
        const {
            description,
            url,
            test,
            production
        } = this.form('description', 'url', 'test', 'production')
        return (
            <Form onSubmit={ev => { ev.preventDefault(); saveWebhook(this.state.form) }}>
                <FormGroup row>
                    <Label sm="2" {...description.label}>{ i18n.gettext('Description')}</Label>
                    <Col sm="10"><Input { ...description.input } /></Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm="2" { ...url.label }>{ i18n.gettext('URL')}</Label>
                    <Col sm="10"><Input { ...url.input } /></Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm="2"></Col>
                    <Col sm="10">
                        <FormGroup check> 
                            <Label check><Input { ...test.input } type="checkbox" checked={test.input.value} /> { i18n.gettext('Test mode')}</Label>
                        </FormGroup>
                        <FormGroup check> 
                            <Label check><Input { ...production.input } type="checkbox" checked={production.input.value} /> { i18n.gettext('Production mode')}</Label>
                        </FormGroup>
                    </Col>
                </FormGroup>
                <Button color="primary" disabled={processing} type="submit">{ i18n.gettext('Save') }</Button> { processing ? <Icon spin name="spinner" /> : null }
            </Form>
        )
    }
    render() {
        const {
            history,
            i18n,
            webhook
        } = this.props
        return (
            <Modal isOpen={true} size="lg">
                <ModalHeader toggle={() => history.push('/settings/webhooks')}>{ webhook.id ? i18n.gettext('Edit webhook') : i18n.gettext('New webhook') }</ModalHeader>
                <ModalBody>
                    { this.renderBody() }
                </ModalBody>
            </Modal>
        )
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(BankAccountStatementModal))

interface IFormFields {
    [key: string]: any
}

const form_helper = (inst: React.Component<any, any>, ...fields: string[]) : IFormFields => {
    const onChange = (ev: any) => {
        let value = ev.target.value
        if (ev.target.type === 'checkbox') value = ev.target.checked
        inst.setState({ form: { ...inst.state.form, [ev.target.name]: value }})
    }
    const state = inst.state
    return Object.assign.apply(null, [{}].concat(fields.map(f => {
        return { [f]: {
            input: { name: f, type: 'text', onChange, id: `fe-${f}`, value: state.form[f] || '' },
            label: { for: `fe-${f}`}
        } }
    })))
}
