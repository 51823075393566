import {
    createHooks
} from "@epeli/redux-hooks";
import { IApplicationState } from "./reducers";
import Jed from 'jed'

const Hooks = createHooks<IApplicationState>()

export default Hooks

const useI18n = () => Hooks.useSelect(state => state.translations.messages, messages => new Jed(messages))
const isProcessing = (type: string) => Hooks.useSelect(state => !!state.process[type])

export { useI18n, isProcessing }