import React from 'react';
import { connect } from 'react-redux';
import { getI18n, ITranslatableContainer } from '../actions/translations';
import { IApplicationState } from '../reducers';
import { Table, Button, Label, Input, Col, Row } from 'reactstrap';
import { IBankAccountStatement, fetchStatements } from '../actions/statements';
import LoadingIndicator from '../components/loading-indicator';
import { bindActionCreators } from 'redux';
import { Link, Route } from 'react-router-dom';
import { IEnvironment } from '../actions/users';
import moment from 'moment'
import BankAccountStatementModal from './bank-account-statement-modal';
import FormGroup from 'reactstrap/lib/FormGroup';

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ fetchStatements }, dispatch)
const mapStateToProps = (state: IApplicationState) => ({
    i18n: getI18n(state),
    loading: state.statements.loading,
    total_count: state.statements.total_count,
    statements: state.statements.statements,
    environment: state.user.environment,
    bank_account_id: state.statements.current_bank_account_id
});

interface IBankAccountStatementProps extends ITranslatableContainer {
    loading: boolean,
    statements: Array<IBankAccountStatement>,
    total_count: number,
    fetchStatements: Function,
    environment: IEnvironment,
    bank_account_id: string
}

class BankAccountStatements extends React.Component<IBankAccountStatementProps> {
    componentDidMount() {
        let bank_account = this.props.bank_account_id || (this.props.environment.bank_accounts[0] || {}).id
        if (!bank_account) return
        this.props.fetchStatements({
            bank_account    
        })
    }
    renderContent() {
        const {
            loading,
            statements,
            total_count,
            i18n,
            fetchStatements,
            bank_account_id
        } = this.props

        return (
            <Table>
                <tbody>
                    { statements.map((st, idx) => (
                        <tr key={idx}>
                            <td><Link to={`/bankaccountstatements/${st.id}`}>{ i18n.gettext('Statement') } { st.legal_sequence_number}</Link></td>
                            <td>{ d(st.from) } - { d(st.to) }</td>
                            <td></td>
                        </tr>
                    ))}
                </tbody>
                { loading ? <tbody><tr><td colSpan={3}><LoadingIndicator /></td></tr></tbody> : null }
                { total_count <= statements.length ? null : (
                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <Button color="primary" outline onClick={() => fetchStatements({ offset: statements.length, bank_account: bank_account_id })}>{ i18n.gettext('Load more')}</Button>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </Table>
        )
        
    }
    render() {
        const {
            i18n,
            environment: {
                bank_accounts
            },
            bank_account_id,
            fetchStatements
        } = this.props;
        return (
            <div>
                <h4>{ i18n.gettext('Bank statements')}</h4>
                { bank_accounts.length ? (
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{ i18n.gettext('Bank account') }</Label>
                            <Input type="select" value={bank_account_id || bank_accounts[0].id} onChange={ev => fetchStatements({ bank_account: ev.target.value })}>
                            { bank_accounts.map((ba, idx) => (
                                <option key={idx} value={ba.id}>{ ba.iban } { ba.customer_information ? ` - ${ba.customer_information.name}` : null }</option>
                            ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                ) : null }
                { this.renderContent() }
                <Route path="/bankaccountstatements/:id" exact component={BankAccountStatementModal} />
            </div>
        )
    }
}

export default connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(BankAccountStatements);

function d(str: string) : string {
    return moment(str).format('DD.MM.YYYY');
}