import api from '../api'
import { updateIn } from './statements';
import Qs from 'qs'

const RECEIVE = 'bankson/inboundPayments/receive'
const LOADING = 'bankson/inboundPayments/loading'
const SET_FILTER = 'bankson/inboundPayments/filter'

export function fetchInboundPayments(filter: any = {}, append=false) {
    return async (dispatch: any) => {
        dispatch({ type: LOADING, bank_account: filter.bank_account })
        dispatch({ type: SET_FILTER, filter })
        dispatch({
            type: RECEIVE,
            append,
            inbound_payments: await api.get(`/inbound-payments?${Qs.stringify(filter)}`)
        })
    }
}

export interface IInboundPayment {
    id: string,
    debitor_name: string,
    amount: number
    archive_id: string,
    payment_date: string,
    reference_number: string
}

export interface IInboundPaymentState {
    current_bank_account_id: string | null;
    payments: IInboundPayment[],
    loading: boolean,
    total_count: number,
    filter: any
}

const defState = {
    payments: [],
    loading: false,
    total_count: 0,
    current_bank_account_id: null,
    filter: {}
}

export default function inboundPaymentReducer(prevState : IInboundPaymentState = defState, action: any = {}) {
    if (action.type === LOADING) return {
        ...prevState,
        loading: true,
        current_bank_account_id: action.bank_account
    }
    if (action.type === RECEIVE) return {
        ...prevState,
        loading: false,
        payments: action.append ? updateIn(prevState.payments, action.inbound_payments.items) : action.inbound_payments.items,
        total_count: action.inbound_payments.total_count
    }
    if (action.type === SET_FILTER) return {
        ...prevState,
        filter: action.filter
    }
    return prevState
}