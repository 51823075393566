import React from 'react'
import { IApplicationState } from '../reducers';
import { Modal, ModalHeader, Row, Col, ModalBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ITranslatableContainer, getI18n } from '../actions/translations';
import { createPayment, IPaymentPayload } from '../actions/payments';
import { fetchBankAccounts, IBankAccount } from '../actions/bank-accounts'
import Icon from 'react-fontawesome'
import LoadingIndicator from '../components/loading-indicator';
import IBAN from 'iban'
import { finnishBICByIBAN } from 'corporate/util/iban'
import moment from 'moment'
import { withRouter } from 'react-router';

const mapStateToProps = (state : IApplicationState, ownProps: any) => ({
    i18n: getI18n(state),
    processing: state.payments.payment_processing,
    error_message: state.payments.payment_error,
    loading: state.bank_accounts.loading,
    bank_accounts: state.bank_accounts.bank_accounts
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    createPayment,
    fetchBankAccounts
}, dispatch)

interface IBankAccountStatementModalProps extends ITranslatableContainer {
    history: any,
    match: any,
    processing: boolean,
    loading: true,
    error_message: string,
    createPayment: (payload: IPaymentPayload, callback: Function) => void,
    fetchBankAccounts: Function,
    bank_accounts: IBankAccount[]
}

interface IThisState extends IPaymentPayload {
    [x: string]: string
}

class BankAccountStatementModal extends React.Component<IBankAccountStatementModalProps, IThisState> {
    constructor(props: IBankAccountStatementModalProps) {
        super(props)
        this.state = {
            recipient_bic: '',
            recipient_iban: '',
            recipient_name: '',
            reference_number: '',
            message: '',
            amount: '',
            payment_date: moment().format('YYYY-MM-DD'),
            source: ''
        }
        this.request = this.request.bind(this)
    }

    componentDidMount() {
        this.props.fetchBankAccounts()
    }

    request(ev: React.FormEvent) {
        ev.preventDefault()
        this.props.createPayment(this.state, () => this.props.history.push('/outbound-payments'))
    }

    renderBody() {
        const {
            i18n,
            processing,
            error_message,
            loading,
            bank_accounts
        } = this.props

        const {
            source,
            recipient_iban,
            recipient_bic,
            recipient_name,
            reference_number,
            message,
            amount,
            payment_date
        } = this.state

        if (loading) return <LoadingIndicator />

        const change = (ev: React.ChangeEvent<HTMLInputElement>)  => {
            let val = ev.target.value, bic = null
            if (ev.target.name === 'recipient_iban') {
                val = val.replace(/ /g, '')
                if (IBAN.isValid(val)) bic = finnishBICByIBAN(val)
                return this.setState({
                    recipient_iban: val,
                    recipient_bic: bic || this.state.recipient_bic
                })
            }
            if (ev.target.name === 'payment_date') {
                let dt = moment_from_format(ev.target.value)
                if (dt.isValid()) return this.setState({
                    payment_date: dt.format('YYYY-MM-DD')
                })
            }
            this.setState({ [ev.target.name]: val })
        }
        return (
            <div>
                <Form onSubmit={this.request}>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-bank-account">{ i18n.gettext('From bank account') }</Label>
                                <Input onChange={change} type="select" name="source" id="fe-bank-account" placeholder={i18n.gettext('Choose bank account')} value={source}>
                                    <option>{ i18n.gettext('Select') }</option>
                                    { bank_accounts.map(ba => (
                                        <option key={ba.id} value={ba.id}>{ IBAN.printFormat(ba.iban) } / { ba.bic }</option>
                                    )) }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-recipient-iban">{ i18n.gettext('Recipient IBAN') }</Label>
                                <Input
                                    invalid={!checkIban(recipient_iban)}
                                    required 
                                    onChange={change}
                                    innerRef={c => c && c.setCustomValidity(checkIban(recipient_iban) ? '' : i18n.gettext('Invalid IBAN number'))}
                                    type="text" name="recipient_iban" id="fe-recipient-id" value={recipient_iban} />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-receipient-bic">{ i18n.gettext('Recipient BIC') }</Label>
                                <Input required onChange={change} type="text" name="recipient_bic" id="fe-recipient-bic" value={recipient_bic} />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-recipient-name">{ i18n.gettext('Recipient name') }</Label>
                                <Input required onChange={change} type="text" name="recipient_name" id="fe-recipient-name" value={recipient_name} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-reference-number">{ i18n.gettext('Reference number') }</Label>
                                <Input onChange={change} type="text" name="reference_number" id="fe-reference-number" value={reference_number} />
                            </FormGroup>
                        </Col>
                        <Col md="8">
                            <FormGroup>
                                <Label for="fe-message">{ i18n.gettext("Message") }</Label>
                                <Input onChange={change} type="text" name="message" id="fe-message" value={message} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-amount">{ i18n.gettext('Amount') }</Label>
                                <Input required onChange={change} type="text" name="amount" id="fe-amount" value={amount} />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-payment-date">{ i18n.gettext('Payment date') }</Label>
                                <Input invalid={!check_payment_date(payment_date)} required onChange={change}
                                    innerRef={c => c && c.setCustomValidity(check_payment_date(payment_date) ? '' : i18n.gettext('Invalid date'))}
                                    type="text" name="payment_date" id="fe-payment-date" value={payment_date} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>

                        {/*}
                    reference_number: '',
            amount: '',
                                    payment_date: '',*/}
                    </Row>
                    <Row>
                        <Col md="4">
                            <Button disabled={processing} color="primary" type="submit">{ i18n.gettext('Create payment') }</Button>
                            { ' ' }
                            { processing ? <Icon name="circle-notch" spin /> : null }
                            { ' ' }
                            { error_message ? <span className="text-danger">{ error_message }</span> : null }
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
    render() {
        const {
            history,
            i18n
        } = this.props
        return (
            <Modal isOpen={true} size="lg" className="modal-full-width">
                <ModalHeader toggle={() => history.push('/outbound-payments')}>{ i18n.gettext('New payment') }</ModalHeader>
                <ModalBody>
                    { this.renderBody() }
                </ModalBody>
            </Modal>
        )
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(BankAccountStatementModal))

function checkIban(iban: string) : boolean {
    if (!iban) return true
    return IBAN.isValid(iban)
}

function moment_from_format(str: string) {
    return moment(str, ['YYYY-MM-DD', 'DD.MM.YYYY', 'DD.MM', 'D.MM', 'D.M', 'D.MM.YYYY', 'D.M.YYYY', 'DD.M.YYYY'], true)
}

function check_payment_date(str: string) : boolean {
    return moment_from_format(str).isValid()
}