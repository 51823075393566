import React from 'react'
import { connect } from 'react-redux';
import { IApplicationState } from '../../reducers';
import { bindActionCreators } from 'redux';
import { fetchWebhooks, IWebhook } from '../../actions/webhooks';
import { ITranslatableContainer, getI18n } from '../../actions/translations';
import LoadingIndicator from '../../components/loading-indicator';
import { Table, Button } from 'reactstrap';
import {Link, Switch, Route, useHistory, withRouter } from 'react-router-dom';
import Icon from 'react-fontawesome'
import WebhookModal from './webhook-modal';

const mapStateToProps = (state: IApplicationState) => ({
    i18n: getI18n(state),
    webhooks: state.webhooks.webhooks,
    loading: state.webhooks.loading
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    fetchWebhooks,
}, dispatch)

interface IWebhooksProps extends ITranslatableContainer {
    fetchWebhooks: Function,
    loading: boolean,
    webhooks: [IWebhook],
    history: any
}

class Webhooks extends React.Component<IWebhooksProps> {
    componentDidMount() {
        this.props.fetchWebhooks();
    }
    renderWebhooks() {
        let {
            webhooks,
            i18n
        } = this.props;

        const testOrProduction = (wh: IWebhook) => {
            if (wh.test && wh.production) return i18n.gettext('Test & Production')
            if (wh.test) return i18n.gettext('Test only')
            if (wh.production) return i18n.gettext('Prodction only')
        }
        return (
            <Table>
                <thead>
                    <tr>
                        <th>{ i18n.gettext('Description')}</th>
                        <th>{ i18n.gettext('URL') }</th>
                        <th>{ i18n.gettext('Production / Test')}</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    { webhooks.map((st, idx) => (
                        <tr key={idx}>
                            <td title={st.description} style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{ st.description }</td>
                            <td>{ st.url }</td>
                            <td style={{whiteSpace: 'nowrap'}}>{ testOrProduction(st) }</td>
                            <td><Link to={`/settings/webhooks/${st.id}`}><Icon name="pencil-alt" /> { i18n.gettext('Edit') }</Link></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
    render() {
        const {
            i18n,
            loading,
            history
        } = this.props
        return (
            <div>
                <h4>
                    { i18n.gettext('Webhooks')}
                </h4>
                <div className="text-right">
                    <Button color="primary" onClick={() => history.push('/settings/webhooks/create')}>
                        <Icon name="plus" /> { i18n.gettext('New webhook') }
                    </Button>
                </div>
                <div>&nbsp;</div>
                { loading ? <LoadingIndicator /> : this.renderWebhooks() }
                <Switch>
                    <Route path="/settings/webhooks/create" component={WebhookModal} />
                    <Route path="/settings/webhooks/:id" component={WebhookModal} />
                </Switch>
            </div>
        )
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(Webhooks))