import api from '../api'
import Qs from 'qs'

const LOADING = 'bankson/statements/loading'
const RECEIVE_STATEMENTS = 'bankson/statements/receiveStatements'

export function fetchStatements({ offset = 0, bank_account = null } = {}) {
    return async (dispatch: Function) => {
        dispatch({ type: LOADING, bank_account })
        let statements = await api.get(`/bankaccountstatements?${Qs.stringify({ offset, bank_account})}`)
        dispatch({
            type: RECEIVE_STATEMENTS,
            statements
        })
    }
}

export interface IBankAccountStatement {
    legal_sequence_number: string
    from: string,
    to: string,
    id: string,
    created_at: string
}

export interface IStatementState {
    loading: boolean,
    statements: Array<IBankAccountStatement>,
    total_count: number,
    current_bank_account_id: string | null
}

const defState = {
    loading: false,
    statements: [],
    total_count: 0,
    current_bank_account_id: null
}

export default function statementReducer(prevState: IStatementState = defState, action: any = { type: '' }) {
    if (action.type === LOADING) return {
        ...prevState,
        current_bank_account_id: action.bank_account,
        statements: action.bank_account === prevState.current_bank_account_id ? prevState.statements : [],
        loading: true
    }
    if (action.type === RECEIVE_STATEMENTS) return {
        ...prevState,
        loading: false,
        total_count: action.statements.total_count,
        statements: updateIn(prevState.statements, action.statements.items)
    }
    return prevState
}

export function updateIn<T extends IIdproperty>(col: T[], obj: T[] | T) : T[] {
    if (obj instanceof Array) return obj.reduce<T[]>((c, o) => updateIn(c, o), col);
    let found = false;
    let ret = col.map(o => {
        if (o.id === obj.id) {
            found = true
            return obj 
        }   
        return o
    }) 
    if (found) return ret as T[];
    return col.concat(obj) as T[]
}
interface IIdproperty {
    id?: string
}