import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../reducers';
import { bindActionCreators } from 'redux';
import { fetchCalls, ICall } from '../actions/calls';
import { getI18n } from '../actions/translations';
import LoadingIndicator from '../components/loading-indicator';
import { Table, Badge, Button, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { Link, Route } from 'react-router-dom';
import moment from 'moment'
import CallModal from './call-modal';
import { IBankCertificate, fetchCertificates } from '../actions/certificates';

const mapStateToProps = (state: IApplicationState) => ({
    i18n: getI18n(state),
    loading: state.calls.loading || state.certificates.loading,
    calls: state.calls.calls,
    total_count: state.calls.total_count,
    certificates: state.certificates.certificates,
    filter: state.calls.filter
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    fetchCalls,
    fetchCertificates
}, dispatch);

interface ICallLogProps {
    fetchCalls: any,
    fetchCertificates: any,
    i18n: any,
    loading: boolean,
    calls: Array<ICall>,
    total_count: number,
    certificates: IBankCertificate[],
    filter: { certificate: string, file_type: string }
}


class CallLog extends React.Component<ICallLogProps> {
    componentDidMount() {
        this.props.fetchCalls({ offset: 0 });
        this.props.fetchCertificates();
    }

    renderCalls() {
        const {
            calls,
            i18n,
            loading,
            total_count,
            fetchCalls,
            filter
        } = this.props
        return (
            <Table responsive={true} style={{whiteSpace: 'nowrap' }}>
                <tbody>
                    { calls.map((call, idx) => (
                        <tr key={idx}>
                            <td><Link to={`/calls/${call.id}`}>{ call.request_id }</Link></td>
                            <td>{ call.request_type }</td>
                            <td title={call.created_at}>{ moment(call.created_at).fromNow() }</td>
                            <td>{ call.success ?
                                <Badge color="success">{ i18n.gettext('Successfull') }</Badge> :
                                <Badge color="danger">{ i18n.gettext('Failed')}</Badge> }</td>
                        </tr>
                    ))}
                </tbody>
                { loading ? <tbody><tr><td colSpan={3}><LoadingIndicator /></td></tr></tbody> : null }
                { total_count <= calls.length ? null : (
                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <Button color="primary" outline onClick={() => fetchCalls({ ...filter, offset: calls.length })}>{ i18n.gettext('Load more')}</Button>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </Table>
        )
    }

    render() {
        const {
            i18n,
            loading,
            filter,
            certificates,
            fetchCalls
        } = this.props
        console.log("filter", filter)
        return (
            <div>
                <h4>{ i18n.gettext('Call log')}</h4>
                { loading ? null : (
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label>{ i18n.gettext('Bank connection') }</Label>
                                <Input onChange={ev => fetchCalls({ offset: 0, certificate: ev.target.value }, false)} type="select" value={filter.certificate ? filter.certificate : ""}>
                                    <option>{ i18n.gettext('All') }</option>
                                    { certificates.map(c =>(
                                        <option value={c.id}>{ `${c.bic} / ${c.bank_customer_id || '(shared)'}` }</option>
                                    ) )}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>{ i18n.gettext('File type') }</Label>
                                <Input onChange={ev => fetchCalls({ ...filter, offset: 0, file_type: ev.target.value }, false)} type="select" value={filter.file_type || ""}>
                                    <option>{ i18n.gettext('All') }</option>
                                    <option value="c2bfeedback">C2B Feedback</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                { this.renderCalls() }
                <Route path="/calls/:id" exact component={CallModal} />
            </div>
        )
    }
}


export default connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(CallLog);