import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import Account from './account'
import BankAccounts from './bank-accounts'
import Certificates from './certificates'
import Webhooks from './webhooks'
import Subscription from "./subscription"
import ApiKeys from "./api-keys"

export default class Settings extends React.Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/settings/account" component={Account} />
                    <Route path="/settings/bank-accounts" component={BankAccounts} />
                    <Route path="/settings/bank-certificates" component={Certificates} />
                    <Route path="/settings/webhooks" component={Webhooks} />
                    <Route path="/settings/subscription" component={Subscription} />
                    <Route path="/settings/api-keys" component={ApiKeys} />
                    <Redirect to="/settings/account" />
                </Switch>
            </div>
        )
    }
}