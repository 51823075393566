import React from 'react'
import { IApplicationState } from '../../reducers';
import { Modal, ModalHeader, Row, Col, ModalBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ITranslatableContainer, getI18n } from '../../actions/translations';
import { saveBankAccount, IBankAccountPayload, IBankAccount } from '../../actions/bank-accounts';
import { fetchCertificates, IBankCertificate } from '../../actions/certificates';
import Icon from 'react-fontawesome'
import LoadingIndicator from '../../components/loading-indicator';
import uniq from 'lodash.uniq';
import IBAN from 'iban'
import { IEnvironment, IUser } from '../../actions/users';
import { withRouter } from 'react-router';


const mapStateToProps = (state : IApplicationState, ownProps: any) => ({
    i18n: getI18n(state),
    processing: state.bank_accounts.processing,
    error_message: state.bank_accounts.process_error,
    loading: state.certificates.loading,
    certificates: state.certificates.certificates,
    environment: state.user.environment,
    current_user: state.user.current_user
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    saveBankAccount,
    fetchCertificates
}, dispatch)

interface IBankAccountModalProps extends ITranslatableContainer {
    history: any,
    match: any,
    processing: boolean,
    error_message: string,
    loading: boolean,
    saveBankAccount: (payload: IBankAccountPayload, cb: (bank_account: IBankAccount) => void) => void,
    fetchCertificates: Function,
    certificates: IBankCertificate[],
    environment: IEnvironment,
    current_user: IUser
}

interface IThisState extends IBankAccountPayload {
    customer_business_id: string,
    customer_name: string,
    customer_contact_name: string,
    customer_contact_email: string,
    customer_contact_ssn: string,
    customer_contact_phone: string,
    authorisation_document: string
    [x: string]: string | undefined | object
}

class BankAccountModal extends React.Component<IBankAccountModalProps, IThisState> {
    constructor(props: IBankAccountModalProps) {
        super(props)
        this.state = {
            certificate: '',
            iban: '',
            contract_id: '',
            customer_name: props.environment.name,
            customer_business_id: props.environment.business_id,
            customer_contact_name: props.current_user.name,
            customer_contact_email: props.current_user.email,
            customer_contact_ssn: '',
            customer_contact_phone: '',
            authorisation_document: ''
        }
        this.request = this.request.bind(this)
    }

    componentDidMount() {
        this.props.fetchCertificates()
    }

    request(ev: React.FormEvent) {
        ev.preventDefault()
        this.props.saveBankAccount({
            iban: this.state.iban,
            certificate: this.state.certificate,
            contract_id: this.state.contract_id,
            customer_information: {
                name: this.state.customer_name,
                business_id: this.state.customer_business_id,
                contact_person: this.state.customer_contact_name,
                contact_person_email: this.state.customer_contact_email,
                contact_person_phone: this.state.customer_contact_phone,
                contact_person_ssn: this.state.customer_contact_ssn
            }
        }, ba => {
            if (!ba.authorisation_document) return this.props.history.push('/settings/bank-accounts')
            this.setState({
                authorisation_document: ba.authorisation_document
            })
        })
    }

    componentWillReceiveProps(newProps: IBankAccountModalProps) {
        if (newProps.certificates && newProps.certificates.length && !this.state.certificate) this.setState({
            certificate: newProps.certificates[0].id
        })
    }

    renderBody() {
        const {
            i18n,
            processing,
            error_message,
            loading,
            certificates
        } = this.props

        const {
            iban,
            certificate,
            contract_id,
            customer_name,
            customer_business_id,
            customer_contact_name = '',
            customer_contact_ssn = '',
            customer_contact_phone = '',
            customer_contact_email = '',
            authorisation_document
        } = this.state

        if (authorisation_document) return (
            <div className="text-center">
                <Button size="lg" color="primary" href={ authorisation_document }>{ i18n.gettext('Download & Print authorisation') }</Button>
            </div>

        )

        const chosen_cert = certificates.find(c => c.id === certificate)

        const change = (ev: React.ChangeEvent<HTMLInputElement>)  => {
            if (ev.target.name === 'iban') {
                this.setState({ iban: ev.target.value.toUpperCase().replace(/ /g, '')})
                return
            }
            this.setState({ [ev.target.name]: ev.target.value })
        }

        if (loading) return <LoadingIndicator />

        const hasDuplicateCerts = hasDuplicates(certificates.map(c => c.bic))

        const BICS : { [x: string]: string } = {
            NDEAFIHH: i18n.gettext('Nordea Bank Finland'),
            OKOYFIHH: i18n.gettext('Osuuspankki'),
            HELSFIHH: i18n.gettext('Aktia'),
            ITELFIHH: i18n.gettext('Oma SP'),
            POPFFI22: i18n.gettext('POP Pankki'),
            DABAFIHH: i18n.gettext('Danske Bank'),
            HANDFIHH: i18n.gettext('Handelsbanken')
        }
        return (
            <div>
                <Form onSubmit={this.request}>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-certificate">{ i18n.gettext('Bank') }</Label>
                                <Input required onChange={change} type="select" name="certificate" id="fe-bic" placeholder={i18n.gettext('Choose bank')} value={certificate}>
                                    <option>{ i18n.gettext('Please choose') }</option>
                                    { certificates.map(c => (
                                        <option key={c.id} value={c.id}>{ BICS[c.bic] }{ hasDuplicateCerts ? ` ( ${c.bank_customer_id} / ${c.subject} )` : ''}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label for="fe-iban">{ i18n.gettext('IBAN number') }</Label>
                                <Input
                                    required onChange={change} type="text" name="iban" id="fe-iban"
                                    value={iban}
                                    invalid={!checkIban(iban)} />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label for="fe-contract-id">{ i18n.gettext('Contract id') }</Label>
                                <Input
                                    onChange={change} type="text" name="contract_id" id="fe-contract-id" value={contract_id} />
                            </FormGroup>
                        </Col>
                    </Row>
                    { !chosen_cert || !chosen_cert.shared ? null : (
                        <Row>
                            <Col md="12">
                                <h4>{ i18n.gettext('Customer information') }</h4>
                            </Col>
                            <Col md="7">
                                <FormGroup>
                                    <Label for="fe-customer-name">{ i18n.gettext('Company name') }</Label>
                                    <Input
                                        required
                                        onChange={change} type="text" name="customer_name" id="fe-customer-name"
                                        value={customer_name} />
                                </FormGroup>
                            </Col>
                            <Col md="5">
                                <FormGroup>
                                    <Label for="fe-customer-business-id">{ i18n.gettext('Business id') }</Label>
                                    <Input
                                        required
                                        onChange={change} type="text" name="customer_business_id" id="fe-customer-business-id"
                                        value={customer_business_id} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="fe-customer-contact-name">{ i18n.gettext('Contact person') }</Label>
                                    <Input 
                                        required
                                        onChange={change} type="text" name="customer_contact_name" id="fe-customer-contact-name"
                                        value={customer_contact_name} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="fe-customer-contact-ssn">{ i18n.gettext('Contact person SSN') }</Label>
                                    <Input
                                        required
                                        onChange={change} type="text" name="customer_contact_ssn" id="fe-customer-contact-ssn"
                                        value={customer_contact_ssn} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="fe-customer-contact-email">{ i18n.gettext('Contact person e-mail') }</Label>
                                    <Input
                                        required
                                        onChange={change} type="text" name="customer_contact_email" id="fe-customer-contact-email"
                                        value={customer_contact_email} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="fe-customer-contact-phone">{ i18n.gettext('Contact person phone') }</Label>
                                    <Input
                                        required
                                        onChange={change} type="text" name="customer_contact_phone" id="fe-customer-contact-phone"
                                        value={customer_contact_phone} />
                                </FormGroup>
                            </Col>
                        </Row>
                    ) }
                    <Row>
                        <Col md="4">
                            <Button disabled={processing || !certificate || !iban} color="primary" type="submit">{ i18n.gettext('Save') }</Button>
                            { ' ' }
                            { processing ? <Icon name="circle-notch" spin /> : null }
                            { ' ' }
                            { error_message ? <span className="text-danger">{ error_message }</span> : null }
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
    render() {
        const {
            push,
            i18n
        } = this.props
        return (
            <Modal isOpen={true} size="lg" className="modal-full-width">
                <ModalHeader toggle={() => push('/settings/bank-accounts')}>{  i18n.gettext('New bank account') }</ModalHeader>
                <ModalBody>
                    { this.renderBody() }
                </ModalBody>
            </Modal>
        )
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(BankAccountModal))

function hasDuplicates(arr: ArrayLike<string>) {
    return uniq(arr).length !== arr.length
}

function checkIban(iban: string) : boolean {
    if (!iban) return true
    return IBAN.isValid(iban)
}