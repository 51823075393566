import { combineReducers } from 'redux';
import { default as translations, ITranslationState } from '../actions/translations';
import { default as user, IUserState } from '../actions/users'
import { default as process, IProcessState } from '../actions/process'
import { default as flash, IFlashState } from '../actions/flash'
import statements, { IStatementState } from '../actions/statements';
import bank_accounts, { IBankAccountState } from '../actions/bank-accounts';
import calls, { ICallState } from '../actions/calls';
import certificates, { IBankCertificateState } from '../actions/certificates';
import webhooks, { IWebhookState } from '../actions/webhooks';
import payments, { IPaymentState } from '../actions/payments';
import inbound_payments, { IInboundPaymentState } from '../actions/inbound-payments';
export default combineReducers<any, any>({
    user,
    translations,
    process,
    flash,
    statements,
    bank_accounts,
    calls,
    certificates,
    webhooks,
    payments,
    inbound_payments
});


export interface IApplicationState {
    translations: ITranslationState,
    user: IUserState,
    process: IProcessState,
    flash: IFlashState,
    statements: IStatementState
    bank_accounts: IBankAccountState,
    calls: ICallState,
    certificates: IBankCertificateState,
    webhooks: IWebhookState,
    payments: IPaymentState,
    inbound_payments: IInboundPaymentState
}

export interface IAction {
    type: string
}