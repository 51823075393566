import { Component } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, NavLink, Redirect, withRouter } from 'react-router-dom';
import { Container, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert, Row, Col } from 'reactstrap';

import { IApplicationState } from '../reducers';
import { getI18n } from '../actions/translations';
import { IUser, logout, setMode } from '../actions/users';
import { IFlash } from '../actions/flash';

import Icon from 'react-fontawesome'

import BankAccountStatements from './bank-account-statements';
import Payments from './payments';
import LoadingIndicator from '../components/loading-indicator';
import Settings from './settings';
import CallLog from './calls';
import InboundPayments from './inbound-payments';

const mapStateToProps = (state: IApplicationState) : any => ({
    i18n: getI18n(state),
    user: state.user.current_user,
    flashes: state.flash.flashes,
    test_mode: state.user.test_mode
});
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    logout,
    setMode
}, dispatch);

interface IAppState {
    isOpen: boolean
}

interface IAppProps {
    i18n: any,
    user: IUser | null,
    logout: Function,
    flashes: Array<IFlash>,
    test_mode: boolean,
    setMode: Function,
    history: any
}

const SettingsNav = connect((state: IApplicationState) => ({ i18n: getI18n(state) }))(({ i18n }) => (
    <Col sm="12" md="2" style={{paddingLeft: 0, paddingRight: 0}}>
        <Nav vertical id="sub-navigation">
            <NavLink className="nav-link" to="/settings/account"><Icon name="user" /> { i18n.gettext('Account')}</NavLink>
            <NavLink className="nav-link" to="/settings/subscription"><Icon name="credit-card" /> { i18n.gettext('Subscription')}</NavLink>
            <NavLink className="nav-link" to="/settings/bank-accounts"><Icon name="building" /> { i18n.gettext('Bank accounts')}</NavLink>
            <NavLink className="nav-link" to="/settings/bank-certificates"><Icon name="certificate" /> { i18n.gettext('Bank certificates')}</NavLink>
            <NavLink className="nav-link" to="/settings/webhooks"><Icon name="globe" /> { i18n.gettext('Webhooks') }</NavLink>
            <NavLink className="nav-link" to="/settings/api-keys"><Icon name="cog" /> { i18n.gettext("API keys") }</NavLink>
        </Nav>
    </Col>
))

const DefaultNav = connect((state: IApplicationState) => ({ i18n: getI18n(state) }))(({ i18n }) => (
    <Nav vertical>
        <NavLink className="nav-link" to="/inbound-payments"><Icon name="arrow-circle-down" /> { i18n.gettext('Inbound Payments')}</NavLink>
        <NavLink className="nav-link" to="/outbound-payments"><Icon name="arrow-circle-up" /> { i18n.gettext('Outbound Payments')}</NavLink>
        <NavLink className="nav-link" to="/bankaccountstatements"><Icon name="stream" /> { i18n.gettext('Bank Statements')}</NavLink>
        <hr />
        <NavLink className="nav-link" to="/settings"><Icon name="cog" /> { i18n.gettext('Settings')}</NavLink>
    </Nav>
))

class App extends Component<IAppProps, IAppState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false
        }
        this.toggle = this.toggle.bind(this);
    }
 
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    render() {
        let {
            i18n,
            user,
            logout,
            history,
            flashes,
            test_mode,
            setMode
        } = this.props;
        if (!user) return <LoadingIndicator />
        return (
            <div>
                <Navbar dark color="primary" expand="md" fixed="top">
                    <Container fluid>
                        <NavbarBrand onClick={(ev: React.MouseEvent) => { ev.preventDefault(); history.push('/') }} href="/">
                            <img style={{maxHeight: '1em'}} src={require('../../assets/logo-white.png')} />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-4" navbar>
                                { test_mode ? <span className="nav-link">{ i18n.gettext('Test mode') }</span> : null }
                            </Nav>
                            <Nav className="ml-auto" navbar>
                            { user ? (
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                { user.name }
                                </DropdownToggle>
                                <DropdownMenu right>
                                <DropdownItem onClick={() => push('/settings/account')}>
                                    { i18n.gettext('My account') }
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => logout()}>
                                    { i18n.gettext('Logout') }
                                </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            ) : null }
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
                <Container fluid>
                    <div id="flashes">
                        { flashes.map((f, idx) => (
                            <Alert key={idx} color={f.color}>{ f.msg }</Alert>
                        ))}
                    </div>
                    <Row style={{position: 'fixed', left: 0, right: 0, bottom: 0, top: 0}}>
                        <Col md="1" sm="12" style={{paddingLeft: 0, paddingRight: 0}}>
                            <div id="main-navigation">
                                <Route path="/" component={DefaultNav} />
                                <Nav vertical>
                                    <NavLink className="nav-link" to="/calls"><Icon name="history" /> { i18n.gettext('Call log')}</NavLink>
                                    <a onClick={(ev: any) => ev.preventDefault() | setMode(!test_mode) } className="nav-link" href="">
                                        <Icon className={test_mode ? 'text-success' : ''} name={ test_mode ? 'toggle-on' : 'toggle-off' } /> { i18n.gettext('Test mode') }
                                    </a>
                                </Nav>
                            </div>
                        </Col>
                        <Route path="/settings" component={SettingsNav} />
                        <Col style={{overflow: 'auto', maxHeight: '100vh', margin: 0, padding: '5em 2em'}}>
                            <div className="clear-fix"></div>
                            <div className="inner">
                                <Switch>
                                    <Route component={InboundPayments} path="/inbound-payments" />
                                    <Route component={BankAccountStatements} path="/bankaccountstatements" />
                                    <Route component={Payments} path="/outbound-payments" />
                                    <Route component={Settings} path="/settings" />
                                    <Route component={CallLog} path="/calls" />
                                    <Redirect to="/inbound-payments" />
                                </Switch>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(App))
