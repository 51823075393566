import React, { useState } from "react"
import { Modal, Label, Input, FormGroup, Button, Alert } from "reactstrap"
import ModalHeader from "reactstrap/lib/ModalHeader"
import { useHistory } from "react-router"
import Hooks, { useI18n, isProcessing } from "../../hooks"
import ModalBody from "reactstrap/lib/ModalBody"
import { createApiKey } from "../../actions/api-keys"

const useActions = () => Hooks.useActionCreators({
    createApiKey
})

const CreateApiKeyModal = () => {
    const history = useHistory()
    const i18n = useI18n()
    const actions = useActions()
    const [data, setData] = useState<any>({ description: ""})
    const processing = isProcessing("apikey")
    return <Modal isOpen={true}>
        <ModalHeader toggle={() => history.push("/settings/api-keys")}>
            { i18n.gettext("Create API key") }
        </ModalHeader>
        <ModalBody>
            <form onSubmit={ev => { ev.preventDefault(); actions.createApiKey(data, setData) }}>
                <FormGroup>
                    <Label>{ i18n.gettext("Description") }</Label>
                    <Input value={data.description} onChange={ev => setData({ ...data, description: ev.target.value })} type="text" />
                </FormGroup>
                <Button type="submit" disabled={processing} color="primary">{ i18n.gettext("Create") }</Button>
            </form>
            { data.api_key ? (
                <div style={{marginTop: "1em"}}>
                    <FormGroup>
                        <Label>{ i18n.gettext("API key id") }</Label>
                        <Input readOnly value={data.api_key} />
                    </FormGroup>
                    <FormGroup>
                        <Label>{ i18n.gettext("Public key") }</Label>
                        <Input type="textarea" rows="5" readOnly value={data.public_key} />
                    </FormGroup>
                    <FormGroup>
                        <Label>{ i18n.gettext("Private key") }</Label>
                        <Input type="textarea" rows="5" readOnly value={data.private_key} />
                    </FormGroup>
                    <Alert color="info">
                        { i18n.gettext("This is the last time you will see the private key. Please note it down.") }
                    </Alert>
                </div>
            ): null }
        </ModalBody>
    </Modal>
}

export default CreateApiKeyModal