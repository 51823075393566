import api from '../api'
import Qs from 'qs'
import { updateIn } from './statements';
import { IApplicationState } from '../reducers';
import { flashError } from './flash';

const LOADING = 'bankson/payments/loading'
const RECEIVE_PAYMENTS = 'bankson/payments/receive'
const PROCESSING = 'bankson/payments/processing'
const REMOVE_PAYMENT = 'bankson/payments/remove'

export function fetchPayments(filter?: any) {
    return async (dispatch: any, getState: () => IApplicationState) => {
        if (typeof filter === "undefined") filter = JSON.parse(getState().payments.filter)
        dispatch({ type: LOADING })
        if (JSON.stringify({...filter, offset: undefined}) !== getState().payments.filter) {
            dispatch({
                type: RECEIVE_PAYMENTS,
                payments: { items: [], total_count: 0 }
            })
        }
        let payments = await api.get(`/outbound-payments?${Qs.stringify(filter)}`)
        dispatch({
            type: RECEIVE_PAYMENTS,
            payments,
            filter: JSON.stringify({...filter, offset: undefined})
        })
    }
}

export function removePayment(payment: IPayment) {
    return async (dispatch: any) => {
        dispatch({ type: LOADING })
        try {
            await api.delete(`/outbound-payments/${payment.id}`)
            dispatch({
                type: REMOVE_PAYMENT,
                id: payment.id
            })
        } catch (e) {
            dispatch(flashError(e.body.message))
            dispatch(fetchPayments())
        }
    }
}

export function copyPayment(payment: IPayment) {
    return async (dispatch: any) => {
        dispatch({ type: LOADING })
        try {
            await api.post(`/outbound-payments/${payment.id}`, { operation: "copy" })
            dispatch(fetchPayments())
        } catch (e) {
            dispatch(flashError(e.body.message))
            dispatch(fetchPayments())
        }
    }
}

export function fetchPayment(id: string) {
    return async (dispatch: any) => {
        dispatch({ type: LOADING })
        let payments = {items: [await api.get(`/outbound-payments/${id}`)]}
        dispatch({
            type: RECEIVE_PAYMENTS,
            payments
        })
    }
}

export function createPayment(payload: IPaymentPayload) {
    return async (dispatch: any) => {
        dispatch({ type: PROCESSING })
        let data = await api.post('/outbound-payments', payload)
        console.log('data', data)
        dispatch(fetchPayments())

    }
}

export interface IPayment {
    recipient_bic: string;
    reference_number?: string;
    payment_date?: string;
    recipient_iban?: string;
    id: string,
    recipient_name: string,
    created_at: string,
    amount: number,
    status: string,
    status_details: string
}

export interface IPaymentPayload {
    recipient_name: string,
    amount: string,
    source: string,
    recipient_iban: string,
    recipient_bic: string,
    reference_number: string,
    payment_date: string
}

export interface IPaymentState {
    loading: boolean,
    payments: IPayment[],
    total_count: number,
    payment_processing: boolean,
    payment_error: string,
    filter: string
}

const defState = {
    loading: false,
    payments: [],
    total_count: 0,
    payment_processing: false,
    payment_error: '',
    filter: ""
}

export default function paymentReducer(prevState: IPaymentState = defState, action: any = {}) {
    if (action.type === LOADING) {
        return {
            ...prevState,
            loading: true
        }
    }
    if (action.type === RECEIVE_PAYMENTS) {
        return {
            ...prevState,
            loading: false,
            total_count: action.payments.total_count,
            payments: action.payments.items.length ? updateIn(prevState.payments, action.payments.items) : [],
            filter: action.filter
        }
    }
    if (action.type === REMOVE_PAYMENT) {
        return {
            ...prevState,
            loading: false,
            total_count: prevState.total_count - 1,
            payments: prevState.payments.filter(paym => paym.id !== action.id)
        }
    }
    return prevState
}