import React from 'react'
import { IApplicationState } from '../../reducers';
import { Modal, ModalHeader, Row, Col, ModalBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ITranslatableContainer, getI18n } from '../../actions/translations';
import { requestCertificate, ICertificateRequestPayload } from '../../actions/certificates';
import Icon from 'react-fontawesome'
import { withRouter } from 'react-router';


const mapStateToProps = (state : IApplicationState, ownProps: any) => ({
    i18n: getI18n(state),
    processing: state.certificates.certificate_request_processing,
    error_message: state.certificates.request_error
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    requestCertificate
}, dispatch)

interface IBankAccountStatementModalProps extends ITranslatableContainer {
    history: any,
    match: any,
    processing: boolean,
    error_message: string,
    requestCertificate: (payload: ICertificateRequestPayload) => void
}

interface IThisState {
    bic: string,
    bank_customer_id: string,
    bank_target_id: string,
    transfer_key1: string,
    transfer_key2: string,
    [x: string]: string
}

class BankAccountStatementModal extends React.Component<IBankAccountStatementModalProps, IThisState> {
    constructor(props: IBankAccountStatementModalProps) {
        super(props)
        this.state = {
            bic: 'DABAFIHH',
            bank_customer_id: '',
            bank_target_id: '',
            transfer_key1: '',
            transfer_key2: ''
        }
        this.request = this.request.bind(this)
    }

    request(ev: React.FormEvent) {
        ev.preventDefault()
        this.props.requestCertificate(this.state)
    }

    renderBody() {
        const {
            i18n,
            processing,
            error_message
        } = this.props

        const {
            bic,
            bank_customer_id,
            bank_target_id,
            transfer_key1,
            transfer_key2
        } = this.state

        const change = (ev: React.ChangeEvent<HTMLInputElement>)  => this.setState({ [ev.target.name]: ev.target.value });
        return (
            <div>
                <Form onSubmit={this.request}>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-bic">{ i18n.gettext('Bank') }</Label>
                                <Input onChange={change} type="select" name="bic" id="fe-bic" placeholder={i18n.gettext('Choose bank')} value={bic}>
                                    <option value="NDEAFIHH">{ i18n.gettext('Nordea Bank Finland') }</option>
                                    <option value="OKOYFIHH">{ i18n.gettext('Osuuspankki') }</option>
                                    <option value="HELSFIHH">{ i18n.gettext('Aktia') }</option>
                                    <option value="POPFFI22">{ i18n.gettext('POP Pankki') }</option>
                                    <option value="ITELFIHH">{ i18n.gettext('Oma SP') }</option>
                                    <option value="HANDFIHH">{ i18n.gettext('Handelsbanken') }</option>
                                    <option value="DABAFIHH">{ i18n.gettext('Danske bank') }</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-customer-id">{ i18n.gettext('Customer ID / Username') }</Label>
                                <Input required onChange={change} type="text" name="bank_customer_id" id="fe-customer-id" value={bank_customer_id} />
                            </FormGroup>
                        </Col>
                        { bic === 'NDEAFIHH' ? <Col md="4">
                            <FormGroup>
                                <Label for="fe-target-id">{ i18n.gettext('Target ID') }</Label>
                                <Input required onChange={change} type="text" name="bank_target_id" id="fe-target-id" value={bank_target_id} />
                            </FormGroup>
                        </Col> : null }
                        <Col md="4">
                            <FormGroup>
                                <Label for="fe-transfer-key1">{ bic === 'NDEAFIHH' || bic === 'DABAFIHH' ? i18n.gettext('Pin code') : i18n.gettext('Transfer Key 1') }</Label>
                                <Input required onChange={change} type="text" name="transfer_key1" id="fe-transfer-key1" value={transfer_key1} />
                            </FormGroup>
                        </Col>
                        { bic !== 'NDEAFIHH' && bic !== 'DABAFIHH' ? <Col md="4">
                            <FormGroup>
                                <Label for="fe-transfer-key2">{ i18n.gettext('Transfer Key 2') }</Label>
                                <Input required onChange={change} type="text" name="transfer_key2" id="fe-transfer-key2" value={transfer_key2} />
                            </FormGroup>
                        </Col> : null }
                    </Row>
                    <Row>
                        <Col md="4">
                            <Button disabled={processing} color="primary" type="submit">{ i18n.gettext('Request') }</Button>
                            { ' ' }
                            { processing ? <Icon name="circle-notch" spin /> : null }
                            { ' ' }
                            { error_message ? <span className="text-danger">{ error_message }</span> : null }
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
    render() {
        const {
            history,
            i18n
        } = this.props
        return (
            <Modal isOpen={true} size="lg" className="modal-full-width">
                <ModalHeader toggle={() => history.push('/settings/bank-certificates')}>{ i18n.gettext('Request certificate from bank') }</ModalHeader>
                <ModalBody>
                    { this.renderBody() }
                </ModalBody>
            </Modal>
        )
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(BankAccountStatementModal))