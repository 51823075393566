import React from 'react'
import { connect } from 'react-redux';
import { IApplicationState } from '../../reducers';
import { bindActionCreators } from 'redux';
import { fetchCertificates, IBankCertificate } from '../../actions/certificates';
import { ITranslatableContainer, getI18n } from '../../actions/translations';
import LoadingIndicator from '../../components/loading-indicator';
import { Table, Button, Badge } from 'reactstrap';
import {Link, Switch, Route, withRouter } from 'react-router-dom';
import moment from 'moment'
import Icon from 'react-fontawesome'
import RequestCertificateModal from './certificate-request-modal';
import CertificateInfoModal from './certificate-info-modal';

const mapStateToProps = (state: IApplicationState) => ({
    i18n: getI18n(state),
    certificates: state.certificates.certificates,
    loading: state.certificates.loading
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    fetchCertificates,
}, dispatch)

interface ICertificatesProps extends ITranslatableContainer {
    fetchCertificates: Function,
    loading: boolean,
    certificates: [IBankCertificate],
    history: any
}

class Certificates extends React.Component<ICertificatesProps> {
    componentDidMount() {
        this.props.fetchCertificates();
    }
    renderBankAccounts() {
        let {
            certificates,
            i18n
        } = this.props;
        return (
            <Table>
                <thead>
                    <tr>
                        <th>{ i18n.gettext('Certificate subject')}</th>
                        <th>{ i18n.gettext('Bank') }</th>
                        <th>{ i18n.gettext('Expires')}</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    { certificates.map((st, idx) => st.shared ? (
                        <tr key={idx}>
                            <td><Badge>{ i18n.gettext('Shared certificate') }</Badge></td>
                            <td colSpan={3}>{ st.bic }</td>
                        </tr>

                    ) : (
                        <tr key={idx}>
                            <td title={st.subject} style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{ st.subject }</td>
                            <td>{ st.bic }</td>
                            <td style={{whiteSpace: 'nowrap'}}>{ moment(st.not_after).fromNow() }</td>
                            <td><Link to={`/settings/bank-certificates/${st.id}`}><Icon name="info-circle" /> { i18n.gettext('Details') }</Link></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
    render() {
        const {
            i18n,
            loading,
            history
        } = this.props
        return (
            <div>
                <h4>
                    { i18n.gettext('Bank certificates')}
                </h4>
                <div className="text-right">
                    <Button color="primary" onClick={() => history.push('/settings/bank-certificates/request')}>
                        <Icon name="bolt" /> { i18n.gettext('Request new certificate from bank') }
                    </Button>
                </div>
                <div>&nbsp;</div>
                { loading ? <LoadingIndicator /> : this.renderBankAccounts() }
                <Switch>
                    <Route path="/settings/bank-certificates/request" component={RequestCertificateModal} />
                    <Route path="/settings/bank-certificates/:id" component={CertificateInfoModal} />
                </Switch>
            </div>
        )
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(Certificates))