import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import Hooks, { useI18n } from "../../hooks"
import { Button, Alert, Row, Col, Table } from "reactstrap"
import LoadingIndicator from "../../components/loading-indicator"
import {
    createCheckoutSession,
    createSubscription,
    fetchSubscription,
    handleCheckoutSession
} from "../../actions/users"
import {
   fetchBankAccounts 
} from "../../actions/bank-accounts"
import Qs from "qs"
import Cards from "react-credit-cards"
import Icon from "react-fontawesome"

const useSelectors = () => Hooks.useSelect(state => ({
    subscription: state.user.subscription,
    stripeCustomer: state.user.stripeCustomer,
    creditCard: state.user.creditCard,
    loading: state.user.loading,
    bankAccountCount: state.bank_accounts.bank_accounts.length
}))

const useActions = () => Hooks.useActionCreators({
    createCheckoutSession,
    createSubscription,
    fetchSubscription,
    handleCheckoutSession,
    fetchBankAccounts
})

const Subscription = withRouter(({ location, history }) => {
    const {
        subscription,
        loading,
        creditCard,
        bankAccountCount
    } = useSelectors()
    const actions = useActions()
    const i18n = useI18n()

    const {
        session_id: sessionId
    } = Qs.parse(location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
        if (!sessionId) return
        actions.handleCheckoutSession(sessionId)
    }, [sessionId])
    useEffect(() => {
        if (!sessionId) return
        if (subscription) history.push("/settings/subscription")
    }, [subscription])
    useEffect(() => {
        actions.fetchSubscription()
        actions.fetchBankAccounts()
    }, [])
    if (loading) return <LoadingIndicator />
    if (!creditCard) return (
        <div>
            <Alert color="info">{ i18n.gettext("No credit card on record") }</Alert>
            <Button color="primary" onClick={() => actions.createCheckoutSession()}>
                { i18n.gettext("Add credit card") }
            </Button>
        </div>
    )
    const subscriptionPricePerAccount = 500
    const totalNoVAT = subscriptionPricePerAccount * bankAccountCount
    return (
        <div id="subscription">
            <Row>
                <Col md={6}>
                    <h4>{ i18n.gettext('Credit card') }:</h4>
                    <Cards
                        preview
                        issuer={creditCard.brand}
                        expiry={`${creditCard.exp_month}/${creditCard.exp_year}`}
                        name={' '}
                        number={`············${creditCard.last4}`}
                        cvc={''}
                    />
                    <p>&nbsp;</p>
                    <p>
                        <small>{ i18n.gettext('Payments secured by') }</small><br /><span className="fab fa-stripe fa-2x"></span>
                    </p>
                </Col>
                <Col md={6}>
                    <h4>{ i18n.gettext('Subscription') }</h4>
                    { !subscription ? (
                        <div>
                            <Alert color="info">{ i18n.gettext('A subscription is needed to use the service in production mode.') }</Alert>
                            <Table className="text-right">
                                <tbody>
                                    <tr>
                                        <td style={{width: '100%'}}>{ i18n.gettext('Bankson subscription')} { (subscriptionPricePerAccount/100).toFixed(2) }€/{i18n.gettext('bank account')} * { bankAccountCount }</td>
                                        <th>{ (totalNoVAT / 100).toFixed(2) }€<small>/{i18n.gettext('mth')}</small></th>
                                    </tr>
                                    <tr>
                                        <td>{ i18n.gettext('VAT') } 24%</td>
                                        <td>{ (totalNoVAT * 0.24 / 100).toFixed(2)}€<small>/{i18n.gettext('mth')}</small></td>
                                    </tr>
                                    <tr>
                                        <th>{ i18n.gettext('In total') }</th>
                                        <th>{ (totalNoVAT * 1.24 / 100).toFixed(2)}€<small>/{i18n.gettext('mth')}</small></th>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className="text-right">
                                <Button onClick={() => actions.createSubscription()} color="primary">
                                    { i18n.gettext('Subscribe') }
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Row>
                            <Col sm={4}>
                                <div className="price">
                                    <strong>{ subscription.quantity }</strong>
                                    <small>{ i18n.gettext('Bank accounts') }</small>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="price">
                                    <strong>{ (subscriptionPricePerAccount / 100).toFixed(0) }€</strong>
                                    <small>{ i18n.gettext('Per account per month') }</small>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="price primary">
                                    <strong>{ (totalNoVAT / 100).toFixed(0) }€</strong>
                                    <small>{ i18n.gettext('Total / month') }</small>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </div>
    )
})

export default Subscription