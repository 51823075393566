import React from 'react'
import { IApplicationState } from '../reducers';
import { Modal, ModalHeader, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { IBankAccountStatement } from '../actions/statements';
import { bindActionCreators } from 'redux';
import { ITranslatableContainer, getI18n } from '../actions/translations';
import ModalBody from 'reactstrap/lib/ModalBody';
import api from '../api'
import moment from 'moment'
import Icon from 'react-fontawesome'
import { withRouter } from 'react-router';


const mapStateToProps = (state : IApplicationState, ownProps: any) => ({
    statement: state.statements.statements.find(({ id }) => id === ownProps.match.params.id),
    i18n: getI18n(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch)

interface IBankAccountStatementModalProps extends ITranslatableContainer {
    statement: IBankAccountStatement,
    history: any
    match: any
}

interface IThisState {
    html?: string | null
}

class BankAccountStatementModal extends React.Component<IBankAccountStatementModalProps, IThisState> {
    constructor(props: IBankAccountStatementModalProps) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.fetchHtml();
    }

    async fetchHtml() {
        let resp = await api.bankAccountStatements.statementHtml(this.props.match.params.id);
        let blob = new Blob([new Uint8Array(resp)], {
            //encoding: 'UTF-8',
            type: 'text/html;charset=UTF-8'
        });

        this.setState({
            html: URL.createObjectURL(blob)
        })
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.setState({
                html: null
            })
            this.fetchHtml();
        }
    }
    renderBody() {
        if (!this.state.html) return null;
        const {
            i18n,
            statement
        } = this.props
        return (
            <div>
                <Row>
                    <Col md={6}>
                        <Button color="primary" onClick={() => (window as any).frames['statementFrame'].print()}><Icon name="print" /> { i18n.gettext('Print') }</Button>
                    </Col>
                    <Col md={6} className="text-right text-muted">
                        { statement.id } / { moment(statement.created_at).fromNow() }
                    </Col>
                </Row>
                <hr />
                <div className="embed-responsive embed-responsive-4by3">
                <iframe ref="statementFrame" name="statementFrame" id="statementFrame" className="embed-responsive-item" src={this.state.html}></iframe>
                </div>
            </div>
        )
    }
    render() {
        const {
            statement,
            history,
            i18n
        } = this.props
        if (!statement) return null;
        return (
            <Modal isOpen={true} size="lg" className="modal-full-width">
                <ModalHeader toggle={() => history.push('/bankaccountstatements')}>{ i18n.gettext('Statement') } { statement.legal_sequence_number }</ModalHeader>
                <ModalBody>
                    { this.renderBody() }
                </ModalBody>
            </Modal>
        )
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(BankAccountStatementModal))