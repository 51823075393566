import api from '../api'
import { updateIn } from './statements';

const LOADING = 'bankson/webhooks/loading'
const RECEIVE = 'bankson/webhooks/receive'
const PROCESSING = 'bankson/webhooks/processing'

export function fetchWebhooks() {
    return async (dispatch: any) => {
        dispatch({ type: LOADING })
        dispatch({ type: RECEIVE, webhooks: (await api.get('/webhooks')).items })
    }
}

export function saveWebhook(data: IWebhook) {
    return async (dispatch: any) => {
        dispatch({ type: PROCESSING })
        let promise = data.id ? api.put(`/webhooks/${data.id}`, data) : api.post('/webhooks', data)
        dispatch({ type: RECEIVE, webhooks: [await promise]})
    }
}

export interface IWebhook {
    description: string,
    test: boolean,
    production: boolean,
    url: string,
    id: string
}

export interface IWebhookState {
    loading: boolean,
    webhooks: Array<IWebhook>,
    processing: boolean
}

const defState = {
    loading: false,
    webhooks: []
}

export default function webhookReducer(prevState: IWebhookState = defState, action: any = {}) {
    if (action.type === LOADING) return {
        ...prevState,
        loading: true
    }
    if (action.type === PROCESSING) return {
        ...prevState,
        processing: true
    }
    if (action.type === RECEIVE) return {
        ...prevState,
        loading: false,
        processing: false,
        webhooks: updateIn<IWebhook>(prevState.webhooks, action.webhooks)
    }
    return prevState
}