import React from 'react'
import { IApplicationState } from '../reducers';
import { Modal, ModalHeader, Row, Col, Table, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { ICall } from '../actions/calls';
import { bindActionCreators } from 'redux';
import { ITranslatableContainer, getI18n } from '../actions/translations';
import moment from 'moment'
import xmlPrettifier from 'xmlchecker/lib/xmlPrettifierModule'
import { withRouter } from 'react-router';


const mapStateToProps = (state : IApplicationState, ownProps: any) => ({
    call: state.calls.calls.find(({ id }) => id === ownProps.match.params.id),
    i18n: getI18n(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch)

interface IBankAccountStatementModalProps extends ITranslatableContainer {
    call: ICall,
    match: any
    history: any
}

interface IThisState {
}

class BankAccountStatementModal extends React.Component<IBankAccountStatementModalProps, IThisState> {
    constructor(props: IBankAccountStatementModalProps) {
        super(props)
        this.state = {}
    }

    renderBody() {
        const {
            i18n,
            call
        } = this.props
        return (
            <div>
                <Row>
                    <Col md={6}>
                        <Table>
                            <tbody>
                                <tr>
                                    <th>{ i18n.gettext('Request ID (bank)')}</th>
                                    <td>{ call.request_id }</td>
                                </tr>
                                <tr>
                                    <th>{ i18n.gettext('Timestamp')}</th>
                                    <td>{ call.created_at }</td>
                                </tr>
                                <tr>
                                    <th>{ i18n.gettext('Request type')}</th>
                                    <td>{ call.request_type }</td>
                                </tr>
                                <tr>
                                    <th>{ i18n.gettext('Status')}</th>
                                    <td className={ call.success ? 'table-success' : 'table-danger' }>{ call.success ? i18n.gettext('Successfull') : i18n.gettext('Failed') }</td>
                                </tr>
                                <tr>
                                    <th>{ i18n.gettext('Details')}</th>
                                    <td>{ call.detail }</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={6} className="text-right text-muted">
                        { call.id } / { moment(call.created_at).fromNow() }
                    </Col>
                </Row>
                <h3>{ i18n.gettext('Application request')}</h3>
                <h6>{ i18n.gettext('Raw request')}</h6>
                <pre>
                    <code>{ formatXML(call.application_request) }</code>
                </pre>
                <h6>{ i18n.gettext('Request content') }</h6>
                <pre>
                    <code>{ getContent(call.application_request) }</code>
                </pre>
                <h3>{ i18n.gettext('Application response') }</h3>
                <h6>{ i18n.gettext('Raw response')}</h6>
                <pre><code>{ formatXML(call.application_response) }</code></pre>
                <h6>{ i18n.gettext('Response content') }</h6>
                <pre>
                    <code>{ getContent(call.application_response) }</code>
                </pre>
            </div>
        )
    }
    render() {
        const {
            call,
            history,
            i18n
        } = this.props
        if (!call) return null;
        return (
            <Modal isOpen={true} size="lg" className="modal-full-width">
                <ModalHeader toggle={() => history.push('/calls')}>{ i18n.gettext('Call') } / { call.request_id } / { call.request_type }</ModalHeader>
                <ModalBody>
                    { this.renderBody() }
                </ModalBody>
            </Modal>
        )
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(BankAccountStatementModal))

function formatXML(str: string): string {
    if (str.indexOf('<?xml') !== 0) str = '<?xml version="1.0" ?>' + str;
    return xmlPrettifier.parse(str);
  }

  function getContent(str: string): string {
      let parser = new DOMParser();
      let dom = parser.parseFromString(str, 'application/xml');
      let content = dom.querySelector('Content');
      if (!content) return '';
      return atob(content.innerHTML);
  }