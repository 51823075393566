
import api from '../api';
import scriptjs from "scriptjs"

import hello from 'hellojs/dist/hello';
import { IBankAccount } from './bank-accounts';
import { flashError } from './flash';

const RECEIVE_USER = 'bankson/receiveUser';
const TOGGLE_TEST = 'bankson/users/toggleTest';
const LOADING = "bankson/users/loading"
const RECEIVE_SUBSCRIPTION = "bankson/users/receiveSubscription"

hello.init({
  bankson: {
    oauth: {
      version: '2',
      auth: `${AUTH_ENDPOINT}/oauth/authorize`,
      grant: `${AUTH_ENDPOINT}/oauth/token`
    },
    base: '/api'
  }
});

hello.init({
  bankson: BANKSON_OAUTH_CLIENT_ID
}, {
  response_type: 'code',
  redirect_uri: '/',
  oauth_proxy: `${AUTH_ENDPOINT}/oauthproxy`
});

function loginNeeded() {
  return () => {
    hello.login('bankson', { response_type: 'code', display: 'page' });
  };
}

export function setMode(testMode: boolean) {
  return (dispatch: any) => {
    localStorage.banksonTest = testMode
    dispatch({
      type: TOGGLE_TEST,
      test: testMode
    })
    window.location.reload()
  }
}

export function createCheckoutSession() {
  return async (dispatch: any) => {
    dispatch({ type: LOADING })
    scriptjs("https://js.stripe.com/v3/", async () => {
      try {
        const { id } = await api.post("/stripe/session", {})
        const stripe = Stripe(STRIPE_KEY) 
        let {error} = await stripe.redirectToCheckout({
          sessionId: id
        })
        console.log('error', error)
      } catch (err) {
          console.log('err', err)
      }
    })
  }
}

export function fetchSubscription() {
  return async (dispatch: any) => {
    dispatch({ type: LOADING })
    try {
      const {
        subscription,
        customer: stripeCustomer,
        credit_card: creditCard
      } = await api.get("/subscription")
      dispatch({ type: RECEIVE_SUBSCRIPTION, subscription, stripeCustomer, creditCard })
    } catch (e) {
      dispatch(flashError(e.message))
      dispatch({ type: LOADING, loading: false })
    }
  }
}
export function createSubscription() {
  return async (dispatch: any) => {
    dispatch({ type: LOADING })
    try {
      const {
        subscription,
        customer: stripeCustomer,
        credit_card: creditCard
      } = await api.post("/stripe/subscription", {})
      dispatch({ type: RECEIVE_SUBSCRIPTION, subscription, stripeCustomer, creditCard  })
    } catch (e) {
      dispatch(flashError(e.message))
    }
  }
}

export function handleCheckoutSession(sessionId: string) {
  return async (dispatch: any) => {
    dispatch({ type: LOADING })
    try {
      dispatch({ type: RECEIVE_SUBSCRIPTION, subscription: await api.post("/stripe/handle-session", {session_id: sessionId}) })
    } catch (e) {
      dispatch(flashError(e.message))
    }
  }
}

export function fetchUser() {
  return async (dispatch: Function) => {
      try {
          let resp = await api.me();
        if (!resp.environment.subscription) localStorage.banksonTest = true;
        else localStorage.banksonTest = typeof localStorage.banksonTest === 'undefined' ? true : localStorage.banksonTest;
        dispatch({
          type: TOGGLE_TEST,
          test: JSON.parse(localStorage.banksonTest)
        })
        dispatch({
            type: RECEIVE_USER,
            user: resp.user,
            environment: resp.environment
        });
    } catch (err) {
        console.log('errored', err);
      if (err.status === 401) return dispatch(loginNeeded());
      throw err;
    }
  };
}

export function logout() {
  return () => {
    hello.logout('bankson', () => {
      window.location.href = '/logout';
    });
  };
}

export interface IUser {
    name: string,
    email: string
}
export interface IEnvironment {
    bank_accounts: Array<IBankAccount>,
    name: string,
    business_id: string
}

export interface IUserState {
    loading: boolean,
    current_user?: IUser,
    environment?: IEnvironment,
    test_mode: boolean,
    subscription?: ISubscription,
    stripeCustomer?: IStripeCustomer,
    creditCard?: ICreditCard
}

export interface ISubscription {
    quantity: number
}

export interface IStripeCustomer {
  invoice_settings: {
    default_payment_method: string | null
  }
}

export interface ICreditCard {
  brand: string,
  last4: string,
  exp_month: string,
  exp_year: string
}

const defState : IUserState = {
    loading: true,
    test_mode: typeof localStorage.banksonTest === 'undefined' ? true : localStorage.banksonTest !== 'false'
}

export default function authReducer(prevState : IUserState = defState, action: any = { type: '' }) : IUserState {
  if (action.type === RECEIVE_USER) return {
    ...prevState,
    current_user: action.user,
    environment: action.environment,
    loading: false
  };
  if (action.type === TOGGLE_TEST) return {
    ...prevState,
    test_mode: action.test
  }
  if (action.type === LOADING) return {
    ...prevState,
    loading: typeof action.loading === "undefined" ? true : action.loading
  }
  if (action.type === RECEIVE_SUBSCRIPTION) return {
    ...prevState,
    loading: false,
    subscription: action.subscription,
    stripeCustomer: action.stripeCustomer,
    creditCard: action.creditCard
  }
  return prevState;
}