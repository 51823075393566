import Qs from 'qs'
import api from '../api';
import { updateIn } from './statements';

const LOADING = 'bankson/calls/loading';
const RECEIVE = 'bankson/calls/receive';

export function fetchCalls(filter = { offset:  0 }, append = true) {
    return async (dispatch: any) => {
        dispatch({ type: LOADING, filter, reset: !append })
        let calls = await api.get(`/calls?${Qs.stringify(filter)}`)
        dispatch({
            type: RECEIVE,
            calls
        })
    }
}

const defState = {
    loading: false,
    calls: [],
    total_count: 0,
    filter: { certificate: null, file_type: null }
}

export interface ICall {
    detail: string;
    created_at: string,
    id: string,
    request_id: string,
    request_type: string,
    success: boolean,
    application_response: string,
    application_request: string

}

export interface ICallState {
    filter: { certificate: string | null, file_type: string | null },
    loading: boolean,
    calls: Array<ICall>,
    total_count: number
}

export default function call_reducer(prevState: ICallState = defState, action: any = { type: '' }) {
    if (action.type === LOADING) return {
        ...prevState,
        loading: true,
        filter: action.filter,
        calls: action.reset ? [] : prevState.calls
    }
    if (action.type === RECEIVE) return {
        ...prevState,
        loading: false,
        calls: updateIn(prevState.calls, action.calls.items),
        total_count: action.calls.total_count
    }
    return prevState;
}