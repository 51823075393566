import { beginProcess, endProcess } from "./process"
import api from "../api"
import { flashError } from "./flash"

export interface IApiKey {
    description: string,
    api_key: string,
    public_key: string,
    private_key?: string
}

export function createApiKey(data: any, cb: (payload: IApiKey) => void) {
    return async (dispatch: any) => {
        dispatch(beginProcess("apikey"))
        try {
            cb(await api.post("/api-keys", data))
        } catch(e) {
            dispatch(flashError(e.message))
        } finally {
            dispatch(endProcess("apikey"))
        }
    }
}