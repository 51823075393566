import React, { useState, useEffect } from 'react'
import { fetchInboundPayments } from '../actions/inbound-payments'
import { Table, Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';
import LoadingIndicator from '../components/loading-indicator';
import moment from 'moment'
import Select from "react-select"
import { IBankAccount } from '../actions/bank-accounts';
import { ValueType } from 'react-select/src/types';
import Hooks, { useI18n } from '../hooks';
import { DateTimePicker } from 'react-widgets';
import Qs from "qs"

const useListState = () => Hooks.useSelect(state => ({
    inbound_payments: state.inbound_payments.payments,
    total_count: state.inbound_payments.total_count,
    loading: state.inbound_payments.loading,
    filter: state.inbound_payments.filter
}))

const useActions = () => Hooks.useActionCreators({
    fetchInboundPayments
})

const InboundPaymentList = () => {
    const {
        inbound_payments,
        loading,
        total_count,
        filter
    } = useListState()
    const i18n = useI18n()
    const actions = useActions()
    return (
        <Table>
            <tbody>
                { inbound_payments.map((st, idx) => (
                    <tr key={idx}>
                        <td><Link to={`/inbound-payments/${st.id}`}>{ st.debitor_name }</Link></td>
                        <td>{ d(st.payment_date) }</td>
                        <td>{ st.amount.toFixed(2) }</td>
                        <td>{ st.reference_number }</td>
                    </tr>
                ))}
            </tbody>
            { loading ? <tbody><tr><td colSpan={3}><LoadingIndicator /></td></tr></tbody> : null }
            { total_count <= inbound_payments.length ? null : (
                <tfoot>
                    <tr>
                        <td colSpan={3}>
                            <Button color="primary" outline onClick={() => actions.fetchInboundPayments({ ...filter, offset: inbound_payments.length }, true)}>{ i18n.gettext('Load more')}</Button>
                        </td>
                    </tr>
                </tfoot>
            )}
        </Table>
    )
        
}

const useAppState = () => Hooks.useSelect(state => ({
    environment: state.user.environment
}))

type OptionType = {
    value: string,
    label: string
}

const InboundPayments = () => {
    const i18n = useI18n()
    const {
        environment
    } = useAppState()
    const actions = useActions()
    const {
        search,
        pathname
    } = useLocation()
    const history = useHistory()

    const [bank_account, setBankAccount] = useState("")
    const [payment_date_min, setPaymentDateMin] = useState("")
    const [payment_date_max, setPaymentDateMax] = useState("")

    useEffect(() => {
        const {
            payment_date_min,
            payment_date_max,
            bank_account
        } = Qs.parse(search, { ignoreQueryPrefix: true })
        if (payment_date_min) setPaymentDateMin(payment_date_min)
        if (payment_date_max) setPaymentDateMax(payment_date_max)
        if (bank_account) setBankAccount(bank_account)
    }, [])

    useEffect(() => {
        if (!bank_account) return
        actions.fetchInboundPayments({
            bank_account,
            payment_date_max,
            payment_date_min,
            offset: 0
        })
        history.push(`${pathname}?${Qs.stringify({ bank_account, payment_date_min, payment_date_max })}`)
    }, [bank_account, payment_date_min, payment_date_max])

    const bankAccountOpts = environment ? environment.bank_accounts.map(ba => ({
        value: ba.id, label: bankAccountLabel(ba)
    })) : []
    return (
        <div>
            <h4>{ i18n.gettext('Inbound Payments')}</h4>
            <Row>
                <Col md={8}>
                    <Row>
                        <Col md={12}>
                            <Select
                                isSearchable
                                options={bankAccountOpts} 
                                placeholder={i18n.gettext("Filter by bank account")}
                                value={bankAccountOpts.find(ba => ba.value === bank_account)}
                                onChange={(val: ValueType<OptionType>) => setBankAccount((val as OptionType).value)}
                                />
                        </Col>
                        <Col md={6}>
                            <br />
                            <DateTimePicker
                                format="YYYY-MM-DD"
                                value={payment_date_min ? moment(payment_date_min).toDate(): undefined}
                                placeholder={i18n.gettext("Date range start")}
                                onChange={val => setPaymentDateMin(val ? moment(val).format("YYYY-MM-DD") : "") }
                                time={false}
                                />
                        </Col>
                        <Col md={6}>
                            <br />
                            <DateTimePicker
                                format="YYYY-MM-DD"
                                value={payment_date_max ? moment(payment_date_max).toDate(): undefined}
                                placeholder={i18n.gettext("Date range end")}
                                onChange={val => setPaymentDateMax(val ? moment(val).format("YYYY-MM-DD") : "")}
                                time={false}
                                />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div>&nbsp;</div> 
            <InboundPaymentList />
        </div>
    )
}

export default InboundPayments


function d(str: string) : string {
    return moment(str).format('DD.MM.YYYY');
}

function bankAccountLabel(ba: IBankAccount) {
    if (!ba.customer_information) return `${ba.iban} / ${ba.bic}`
    return `${ba.iban} / ${ba.bic} / ${ba.customer_information.name}`
}