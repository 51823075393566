import React from "react"
import { useI18n } from "../../hooks"
import { Button } from "reactstrap"
import { useHistory, Route } from "react-router"
import Icon from "react-fontawesome"
import CreateApiKeyModal from "./create-api-key-modal"

const ApiKeys = () => {
    const i18n = useI18n()
    const history = useHistory()
    return (
        <div>
            <h4>{ i18n.gettext("API keys") }</h4>
            <div className="text-right">
                <Button color="primary" onClick={() => history.push('/settings/api-keys/create')}>
                    <Icon name="plus" /> { i18n.gettext('Create API key') }
                </Button>
            </div>
            <Route path="/settings/api-keys/create" component={CreateApiKeyModal} />
        </div>
    )
}

export default ApiKeys