import api from '../api'
import { updateIn } from './statements';
import Qs from "qs"

const LOADING = 'bankson/certificates/loading'
const RECEIVE = 'bankson/certificates/receive'
const START_REQUEST = 'bankson/certificates/start_request'
const REQUEST_ERROR = 'bankson/certificates/request_error'


export function fetchCertificates(filter : any = {}) {
    return async (dispatch: any) => {
        dispatch({ type: LOADING, filter })
        dispatch({ type: RECEIVE, certificates: (await api.get(`/bank-certificates?${Qs.stringify(filter)}`)).items })
    }
}

export interface ICertificateRequestPayload {
    bank_customer_id: string,
    bank_target_id: string,
    transfer_key1: string,
    transfer_key2: string,
    bic: string
}

export function requestCertificate(payload: ICertificateRequestPayload) {
    return async (dispatch: any) => {
        dispatch({ type: START_REQUEST })
        try {
            let resp = await api.post('/bank-certificates/request', payload)
            dispatch({ type: RECEIVE, certificates: resp.items })
        } catch (e) {
            dispatch({ type: REQUEST_ERROR, reason: e.body ? (e.body.message || e.body.error || e.body) : 'Unknown error' })
        }
    }
}

export interface IBankCertificate {
    id: string,
    bic: string,
    bank_customer_id: string,
    bank_target_id: string,
    subject: string,
    not_after: string,
    not_before: string,
    shared?: boolean
}

export interface IBankCertificateState {
    filter: {  }
    certificates: Array<IBankCertificate>,
    loading: boolean,
    certificate_request_processing: boolean,
    request_error?: string
}

const defState = {
    certificates: [],
    loading: false,
    certificate_request_processing: false,
    filter: { certificate: null }
}

export default function bankAccountReducer(prevState: IBankCertificateState = defState, action: any = {}) : IBankCertificateState {
    if (action.type === LOADING) return {
        ...prevState,
        loading: true,
        filter: action.filter
    }
    if (action.type === RECEIVE) return {
        ...prevState,
        loading: false,
        certificate_request_processing: false,
        certificates: updateIn<IBankCertificate>(prevState.certificates, action.certificates)
    }
    if (action.type === START_REQUEST) return {
        ...prevState,
        certificate_request_processing: true,
        request_error: ''
    }
    if (action.type === REQUEST_ERROR) return {
        ...prevState,
        certificate_request_processing: false,
        request_error: action.reason
    }
    return prevState
}