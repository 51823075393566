import { IApplicationState } from "../reducers";
import Jed from 'jed'

const defState = {
    messages: []
}

export default function reducer(prevState : ITranslationState = defState, action : any = {}) {
    return prevState;
}

export function getI18n(state: IApplicationState) : Jed {
    return new Jed(state.translations.messages);
}

export interface ITranslationState {
    messages: any
}

export interface ITranslatableContainer {
    i18n: any
}