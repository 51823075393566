import React from 'react'
import { connect } from 'react-redux';
import { IApplicationState } from '../../reducers';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Gravatar from 'react-gravatar'
import { IUser } from '../../actions/users';
import { ITranslatableContainer, getI18n } from '../../actions/translations';
import Icon from 'react-fontawesome';

const mapStateToProps = (state: IApplicationState) => ({
    user: state.user.current_user,
    i18n: getI18n(state)
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch)

interface IAccountProps extends ITranslatableContainer {
    user: IUser
}

interface IAccountState {
    editMode: boolean
}

class Account extends React.Component<IAccountProps, IAccountState> {
    constructor(props: IAccountProps) {
        super(props)
        this.state = {
            editMode: false
        }
        this.toggleEdit = this.toggleEdit.bind(this)
    }
    toggleEdit() {
        this.setState({
            editMode: !this.state.editMode
        })
    }
    render() {
        const {
            user: {
                email,
                name
            },
            i18n
        } = this.props
        return (
            <div>
                <h4>
                    {i18n.gettext('Account')}
                </h4>
                <div style={{float: 'right'}}>
                    <Button color="secondary" onClick={this.toggleEdit}><Icon name="edit" /> { i18n.gettext('Edit information') }</Button>
                </div>
                <Row>
                    <Col md="auto">
                        <Gravatar size={150} email={email} />
                    </Col>
                    <Col>
                        <dl>
                            <dt>{ i18n.gettext('Name') }</dt>
                            <dd>{ name }</dd>
                            <dt>{ i18n.gettext('Email') }</dt>
                            <dd>{ email }</dd>
                        </dl>
                    </Col>
                </Row>
                <Modal isOpen={this.state.editMode}>
                    <ModalHeader toggle={this.toggleEdit}>{ i18n.gettext('Edit account information')}</ModalHeader>
                    <ModalBody>
                        Edit here
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)