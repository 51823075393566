import api from '../api'
import { updateIn } from './statements';
import { flashError, flashSuccess } from './flash';
import { getI18n } from './translations';

const LOADING = 'bankson/bankAccounts/loading'
const RECEIVE = 'bankson/bankAccounts/receive'
const PROCESSING = 'bankson/bankAccounts/processing'
const NOT_PROCESSING = 'bankson/bankAccounts/notProcessing'
const PROCESS_ERROR = 'bankson/bankAccounts/processError'
const REMOVE_BANK_ACCOUNT = 'bankson/bankAccounts/removeBankAccount'


export function fetchBankAccounts() {
    return async (dispatch: any) => {
        dispatch({ type: LOADING })
        dispatch({ type: RECEIVE, bank_accounts: (await api.get(`/bank-accounts?limit=999999`)).items })
    }
}

export function removeBankAccount(ba: IBankAccount) {
    return async (dispatch: any, getState: any) => {
        const i18n = getI18n(getState())
        dispatch({ type: LOADING })
        try {
            await api.delete(`/bank-accounts/${ba.id}`)
            dispatch({
                type: REMOVE_BANK_ACCOUNT,
                id: ba.id
            })
            dispatch(flashSuccess(i18n.gettext("Bank account removed")))
        } catch (e) {
            dispatch(flashError(e.body.message))
            dispatch(fetchBankAccounts())
        }
    }
}

export function saveBankAccount(payload: IBankAccountPayload, cb: (bank_account: IBankAccount) => void) {
    return async (dispatch: any) => {
        let p = payload.id ? api.put(`/bank-accounts/${payload.id}`, payload) : api.post(`/bank-accounts`, payload)
        dispatch({ type: PROCESSING })
        try {
            let ba = await p
            dispatch({ type: RECEIVE, bank_accounts: [(ba)]})
            if (cb) cb(ba)
        } catch (e) {
            dispatch({ type: PROCESS_ERROR, message: e.body && e.body.message || e.body })
        }
    }
}

export function refreshAccountBalance(id: string) {
    return async (dispatch: any) => {
        dispatch({ type: PROCESSING })
        try {
            let ba = await api.post(`/bank-accounts/${id}/balance`)
            dispatch({ type: RECEIVE, bank_accounts: [(ba)]}) 
        } catch (e) {
            dispatch({ type: NOT_PROCESSING })
            dispatch(flashError(e.body && e.body.message || e.body))
        }
    }
}

export interface IBankAccount {
    id: string,
    iban: string,
    bic: string,
    authorisation_document?: string,
    balance: number,
    balance_date: string,
    customer_information?: {
        name: string,
        business_id: string,
        contact_person: string,
        contact_person_ssn: string,
        contact_person_email: string,
        contact_person_phone: string
    }
}

export interface IBankAccountPayload {
    iban: string,
    certificate: string,
    contract_id: string,
    id?: string,
    customer_information?: {
        name: string,
        business_id: string,
        contact_person: string,
        contact_person_ssn: string,
        contact_person_email: string,
        contact_person_phone: string
    }
}

export interface IBankAccountState {
    bank_accounts: Array<IBankAccount>,
    loading: boolean,
    processing: boolean,
    process_error: string
}

const defState = {
    bank_accounts: [],
    loading: false,
    processing: false,
    process_error: ''
}

export default function bankAccountReducer(prevState: IBankAccountState = defState, action: any = {}) : IBankAccountState {
    if (action.type === LOADING) return {
        ...prevState,
        loading: true
    }
    if (action.type === RECEIVE) return {
        ...prevState,
        loading: false,
        processing: false,
        bank_accounts: updateIn<IBankAccount>(prevState.bank_accounts, action.bank_accounts)
    }
    if (action.type === REMOVE_BANK_ACCOUNT) return {
        ...prevState,
        loading: false,
        processing: false,
        bank_accounts: prevState.bank_accounts.filter(ba => ba.id !== action.id)
    }
    if (action.type === PROCESSING) return {
        ...prevState,
        processing: true
    }
    if (action.type === NOT_PROCESSING) return {
        ...prevState,
        processing: false
    }

    if (action.type === PROCESS_ERROR) return {
        ...prevState,
        processing: false,
        process_error: action.message
    }
    return prevState
}