const FLASH = 'kipa/flash/flash'
const DISMISS = 'kipa/flash/dismiss'

export function flashSuccess(msg: String) {
    const id = Date.now()
    return (dispatch: Function) => {
        dispatch({
            type: FLASH,
            color: 'success',
            msg,
            id
        })
        setTimeout(() => {
            dispatch({
                type: DISMISS,
                id
            })
        }, 5000)
    }
}

export function flashError(msg: String) {
    const id = Date.now()
    return (dispatch: Function) => {
        dispatch({
            type: FLASH,
            color: 'danger',
            msg,
            id
        })
        setTimeout(() => {
            dispatch({
                type: DISMISS,
                id
            })
        }, 5000)
    }
}

export interface IFlash {
    msg: string,
    color: string,
    id: number
}



export interface IFlashState {
    flashes: Array<IFlash>
}

const defState = {
    flashes: []
}

export default function reducer(prevState: IFlashState = defState, action : any = {}) : IFlashState {
    if (action.type === FLASH) return {
        ...prevState,
        flashes: prevState.flashes.concat({
            msg: action.msg,
            color: action.color,
            id: action.id
        })
    }
    if (action.type === DISMISS) return {
        ...prevState,
        flashes: prevState.flashes.filter(f => f.id !== action.id)
    }
    return prevState
}