import hello from 'hellojs/dist/hello';
import Client from 'bankson-js';

function getSession() {
  return hello.getAuthResponse('bankson');
}

function validToken() {
  let session = getSession()
    , currentTime = (new Date()).getTime() / 1000;
  return session && session.access_token && session.expires > currentTime && session.access_token;
}

function ensureLogin() {
  let token = validToken(), p;
  p =  token || !getSession() ?
    Promise.resolve(token) :
    Promise.resolve(hello.login('bankson', { display: 'none' }).then(() => validToken(), () => null));
  return p.then(token  => ({
    bearerToken: token,
    test: window.localStorage.banksonTest !== 'false'
  })).catch(err => {
    console.error('ensureLogin error', err)
    throw err
  })
}

const client = new Client({
  beforeRequest: ensureLogin,
  baseUrl: '/api/v2'
});

export default client;