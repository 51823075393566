import React from 'react'
import { IApplicationState } from '../../reducers';
import { Modal, ModalHeader, Row, Col, Table, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ITranslatableContainer, getI18n } from '../../actions/translations';
import { IBankCertificate } from '../../actions/certificates';
import { withRouter } from 'react-router';


const mapStateToProps = (state : IApplicationState, ownProps: any) => ({
    certificate: state.certificates.certificates.find(({ id }) => id === ownProps.match.params.id),
    i18n: getI18n(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch)

interface IBankAccountStatementModalProps extends ITranslatableContainer {
    history: any
    certificate: IBankCertificate
    match: any
}

interface IThisState {
}

class BankAccountStatementModal extends React.Component<IBankAccountStatementModalProps, IThisState> {
    constructor(props: IBankAccountStatementModalProps) {
        super(props)
        this.state = {}
    }

    renderBody() {
        const {
            i18n
        } = this.props
        return (
            <div>
            </div>
        )
    }
    render() {
        const {
            history,
            i18n,
            certificate
        } = this.props
        if (!certificate) return null
        return (
            <Modal isOpen={true} size="lg" className="modal-full-width">
                <ModalHeader toggle={() => history.push('/settings/bank-certificates')}>{ i18n.gettext('Certificate') }</ModalHeader>
                <ModalBody>
                    { this.renderBody() }
                </ModalBody>
            </Modal>
        )
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(BankAccountStatementModal))