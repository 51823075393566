const BEGIN_PROCESS = 'kipa/beginProcess'
const END_PROCESS = 'kipa/endProcess';

export function beginProcess(process_type: string) {
    return {
        type: BEGIN_PROCESS,
        process_type
    }
}

export function endProcess(process_type: string) {
    return {
        type: END_PROCESS,
        process_type
    }
}

export interface IProcessState {
    [key: string]: boolean
}

const defState = {
    user: false
}

export default function reducer(prevState: IProcessState = defState, action: any = {}) : IProcessState {
    if (action.type === BEGIN_PROCESS) {
        return {
            ...prevState,
            [action.process_type]: true
        }
    }
    if (action.type === END_PROCESS) {
        return {
            ...prevState,
            [action.process_type]: false
        }
    }
    return prevState
}