import React from 'react'
import { connect } from 'react-redux';
import { IApplicationState } from '../../reducers';
import { bindActionCreators } from 'redux';
import { fetchBankAccounts, removeBankAccount, IBankAccount } from '../../actions/bank-accounts';
import { ITranslatableContainer, getI18n } from '../../actions/translations';
import LoadingIndicator from '../../components/loading-indicator';
import { Table, Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {Link, Route, Switch, withRouter } from 'react-router-dom';
import Icon from 'react-fontawesome';
import BankAccountModal from './bank-account-modal'
import BankAccountDetailsModal from './bank-account-details-modal'
import { IEnvironment } from '../../actions/users';

const mapStateToProps = (state: IApplicationState) => ({
    i18n: getI18n(state),
    bank_accounts: state.bank_accounts.bank_accounts,
    loading: state.bank_accounts.loading,
    environment: state.user.environment
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    fetchBankAccounts,
    removeBankAccount
}, dispatch)

interface IBankAccountProps extends ITranslatableContainer {
    fetchBankAccounts: Function,
    removeBankAccount: Function,
    loading: boolean,
    bank_accounts: [IBankAccount],
    history: any,
    environment: IEnvironment
}

class BankAccounts extends React.Component<IBankAccountProps> {
    constructor(props: IBankAccountProps) {
        super(props)
        this.removeBankAccount = this.removeBankAccount.bind(this)
    }
    removeBankAccount(ba: IBankAccount) {
        const i18n = this.props.i18n
        if (!confirm(i18n.gettext("Are you sure?"))) return
        this.props.removeBankAccount(ba)
    }
    componentDidMount() {
        this.props.fetchBankAccounts();
    }
    renderBankAccounts() {
        let {
            bank_accounts,
            environment,
            i18n
        } = this.props;
        return (
            <Table>
                <tbody>
                    { bank_accounts.map((st, idx) => (
                        <tr key={idx}>
                            <td><Link to={`/settings/bank-accounts/${st.id}`}>{ st.iban }</Link></td>
                            <td>{ st.bic }</td>
                    <td style={{width: '100%'}}>{ st.customer_information ? st.customer_information.name : <i style={{color: "#ccc"}}> { environment.name } </i> }</td>
                            <td>
                                <UncontrolledDropdown>
                                    <DropdownToggle color="link" size="xs" style={{ padding: 0 }}>
                                        <Icon name="ellipsis-h" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>{ i18n.gettext("Actions") }</DropdownItem>
                                        <DropdownItem onClick={() => this.removeBankAccount(st)}>
                                            <span className="text-danger">
                                                <Icon name="trash" /> { ' '}
                                                { i18n.gettext("Remove") }
                                            </span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
    render() {
        const {
            i18n,
            loading,
            history
        } = this.props
        return (
            <div>
                <h4>
                    { i18n.gettext('Bank accounts')}
                </h4>
                <div className="text-right">
                    <Button color="primary" onClick={() => history.push('/settings/bank-accounts/new')}>
                        <Icon name="plus" /> { i18n.gettext('New bank account') }
                    </Button>
                </div>
                <div>&nbsp;</div>
                { loading ? <LoadingIndicator /> : this.renderBankAccounts() }
                <Switch>
                    <Route path="/settings/bank-accounts/new" component={BankAccountModal} />
                    <Route path="/settings/bank-accounts/:id" component={BankAccountDetailsModal} />
                </Switch>
            </div>
        )
    }
}

export default withRouter(connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(BankAccounts))