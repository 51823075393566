
import 'react-credit-cards/es/styles-compiled.css'
import '../styles/main.sass'
import 'react-widgets/dist/css/react-widgets.css'

import { fetchUser } from './actions/users';
import App from './containers/app';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';


import {
    createStore,
    applyMiddleware,
    compose
  } from 'redux';

import thunk from 'redux-thunk';

import reducers from './reducers';

import { Route, Router } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import { HooksProvider } from '@epeli/redux-hooks';
import { fetchBankAccounts } from './actions/bank-accounts';



const history = createHistory({});

const devToolsExtension: Function = (window as any)['__REDUX_DEVTOOLS_EXTENSION__'];

const store = createStore(
    reducers,
    compose(
      applyMiddleware(
        thunk,
      ),
      devToolsExtension ? devToolsExtension() : (f: any) => f
    )
  );

(async () => {
  if (window.location.href.indexOf('code') === -1) {
    store.dispatch<any>(fetchUser()); 
    store.dispatch<any>(fetchBankAccounts());
  }
  render((
      <Provider store={store}>
        <HooksProvider store={store}>
          <Router history={history}>
              <Route component={App} />
          </Router>
          </HooksProvider>
      </Provider>
  ), document.querySelector('#app'));
})();
