import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import Hooks, { useI18n } from "../hooks";
import { fetchPayment } from "../actions/payments"
import { withRouter } from "react-router";
import LoadingIndicator from "../components/loading-indicator";
import { paymentStatusText, paymentStatusStyle } from "./payments";
import Qs from "qs"

const useActions = () => Hooks.useActionCreators({
    fetchPayment
})

const useState = ({id}: {id:string}) => Hooks.useSelect(state => ({
    loading: state.payments.loading,
    payment: state.payments.payments.find(p => p.id === id),
    filter: state.payments.filter
}))

const PaymentDetails = withRouter(({history, match}) => {
    const actions = useActions()
    const i18n = useI18n()
    const {
        loading,
        payment,
        filter
    } = useState({ id: match.params.id })

    useEffect(() => {
        actions.fetchPayment(match.params.id)
    }, [])
    return (
        <Modal isOpen={true} size="lg" className="modal-full-width">
            <ModalHeader toggle={() => history.push({pathname:'/outbound-payments', search: Qs.stringify(filter)})}>{ i18n.gettext('Payment') }</ModalHeader>
            <ModalBody>
                { loading || !payment ? <LoadingIndicator /> : (
                    <Table>
                        <tbody>
                            <tr>
                                <th>{ i18n.gettext("Recipient") }</th>
                                <td>{ payment.recipient_name }</td>
                            </tr>
                            <tr>
                                <th>{ i18n.gettext("Recipient IBAN") }</th>
                                <td>{ payment.recipient_iban } / { payment.recipient_bic }</td>
                            </tr>
                            
                            <tr>
                                <th>{ i18n.gettext("Payment date") }</th>
                                <td>{ payment.payment_date }</td>
                            </tr>
                            <tr>
                                <th>{ i18n.gettext("Reference number") }</th>
                                <td>{ payment.reference_number }</td>
                            </tr>
                            
                            <tr>
                                <th>{ i18n.gettext("Status") }</th>
                                <td className={paymentStatusStyle(payment.status)}>{ paymentStatusText(i18n, payment.status) }</td>
                            </tr>
                            <tr>
                                <th>{ i18n.gettext("Status details") }</th>
                                <td>{ payment.status_details }</td>
                            </tr>
                        </tbody>
                    </Table>
                )}
            </ModalBody>
        </Modal>
    )
})

export default PaymentDetails
