import React from 'react'
import Icon from 'react-fontawesome'

export default class LoadingIndicator extends React.Component {
    render() {
        return (
            <p className="text-center">
                <Icon name="circle-notch" spin size="2x" />
            </p>
        )
    }
}