import { withRouter } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, Table, Button, ModalFooter, Row, Col, Alert } from 'reactstrap';
import Hooks, { useI18n } from '../../hooks'
import IBAN from 'iban'
import moment from 'moment'
import Icon from 'react-fontawesome';
import { refreshAccountBalance, removeBankAccount } from '../../actions/bank-accounts'
import { fetchStatements } from "../../actions/statements"
import { fetchPayments } from "../../actions/payments"
import { fetchInboundPayments } from "../../actions/inbound-payments"
import LoadingIndicator from '../../components/loading-indicator';

const BankAccountDetailsModal = withRouter(({ history, match: { params: { id }}}) => {
    let bank_accounts = Hooks.useSelect(state => state.bank_accounts.bank_accounts)
    let processing = Hooks.useSelect(state => state.bank_accounts.processing)
    let actions = Hooks.useActionCreators({ removeBankAccount, refreshAccountBalance, fetchStatements, fetchPayments, fetchInboundPayments })
    let i18n = useI18n()
    let bank_statement_count = Hooks.useSelect(state => state.statements.total_count)
    let outbound_payment_count = Hooks.useSelect(state => state.payments.total_count)
    let inbound_payment_count = Hooks.useSelect(state => state.inbound_payments.total_count)
    const [promptConfirmation, setPromptConfirmation] = useState(false)

    useEffect(() => {
        actions.fetchInboundPayments({ bank_account: id })
        actions.fetchPayments({ bank_account: id })
        actions.fetchStatements({ bank_account: id })
    }, [id])
    
    if (!bank_accounts || !bank_accounts.length) return null
    let bank_account = bank_accounts.find(ba => ba.id === id)
    if (!bank_account) return null
    return (
        <Modal isOpen={true} size="lg">
            <ModalHeader toggle={() => history.push({pathname: '/settings/bank-accounts'})}>{  i18n.gettext('Bank Account') } <small>{ IBAN.printFormat(bank_account.iban) }</small></ModalHeader>
            { promptConfirmation ? (
                <ModalBody>
                    <Alert color="danger">
                        { i18n.gettext("Removing the bank account will remove it from your billing. This is an irreversible operation. All related data (statements and payments) are also removed.") }
                    </Alert>
                </ModalBody>
            ) : (
                <ModalBody>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <Button color="primary" block style={{marginBottom: "1em"}} onClick={() => history.push(`/bankaccountstatements?bank_account=${id}`)}>
                            { i18n.gettext("Bank statements") } ({bank_statement_count})
                        </Button>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Button color="primary" block style={{marginBottom: "1em"}} onClick={() => history.push(`/inbound-payments?bank_account=${id}`)}>
                            { i18n.gettext("Inbound payments") } ({inbound_payment_count})
                        </Button>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Button color="primary" block style={{marginBottom: "1em"}} onClick={() => history.push(`/outbound-payments?bank_account=${id}`)}>
                            { i18n.gettext("Outbound payments") } ({outbound_payment_count})
                        </Button>
                    </Col>
                </Row>
                { processing ? <LoadingIndicator /> : <Table>
                    <tbody>
                        <tr>
                            <th>{ i18n.gettext('Balance') }</th>
                            <td>{ typeof bank_account.balance !== 'number' ? 'N/A' : bank_account.balance.toFixed(2) }€</td>
                        </tr>
                        <tr>
                            <th>{ i18n.gettext('Balance last updated') }</th>
                            <td>{ bank_account.balance_date === null ? i18n.gettext('Never') : moment(bank_account.balance_date).fromNow() } <Button size="sm" onClick={() => actions.refreshAccountBalance(id)}><Icon name="sync" /></Button></td>
                        </tr>
                    </tbody>
                </Table> }
            </ModalBody> )}
            <ModalFooter>
                <Button color="danger" onClick={() => {
                    if (!promptConfirmation) { setPromptConfirmation(true); return }
                    if (!bank_account) return 
                    actions.removeBankAccount(bank_account)
                    history.push("/settings/bank-accounts")
                }}>
                    <Icon name="trash" /> { promptConfirmation ? i18n.gettext("Confirm") : i18n.gettext("Remove bank account") }
                </Button>
            </ModalFooter>
        </Modal>
    )
})

export default BankAccountDetailsModal