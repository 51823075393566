import React from 'react';
import { connect } from 'react-redux';
import { getI18n, ITranslatableContainer } from '../actions/translations';
import { IApplicationState } from '../reducers';
import { fetchPayments, removePayment, IPayment, copyPayment } from '../actions/payments';
import LoadingIndicator from '../components/loading-indicator';
import { bindActionCreators } from 'redux';
import { Table, Button, Badge, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import Icon from 'react-fontawesome'
import NewPaymentModal from './new-payment-modal'
import PaymentDetailsModal from "./payment-details"
import Select from "react-select"
import { IBankAccount } from '../actions/bank-accounts';
import Qs from "qs"
import { ValueType } from 'react-select/src/types';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import momentLocalizer from 'react-widgets-moment';
import moment  from "moment"

momentLocalizer()


const mapDispatchToProps = (dispatch: any) => bindActionCreators({ fetchPayments, removePayment, copyPayment }, dispatch);
const mapStateToProps = (state: IApplicationState) => ({
    i18n: getI18n(state),
    loading: state.payments.loading,
    payments: state.payments.payments,
    total_count: state.payments.total_count,
    bank_accounts: state.bank_accounts.bank_accounts,
});

type OptionType = {
    value: string,
    label: string
}

interface IPaymentsProps extends ITranslatableContainer {
    loading: boolean,
    fetchPayments: Function,
    removePayment: (paym: IPayment) => void,
    copyPayment: (paym: IPayment) => void,
    payments: IPayment[],
    total_count: number,
    bank_accounts: IBankAccount[],
    history: any,
    location: any
}

class Payments extends React.Component<IPaymentsProps> {
    constructor(props: IPaymentsProps) {
        super(props)

        this.removePayment = this.removePayment.bind(this)
        this.resendPayment = this.resendPayment.bind(this)
    }
    removePayment(payment: IPayment) {
        const i18n = this.props.i18n
        if (!confirm(i18n.gettext("Are you sure - this cannot be reversed"))) return
        this.props.removePayment(payment)

    }
    resendPayment(payment: IPayment) {
        this.props.copyPayment(payment)
    }
    fetchPayments(props: IPaymentsProps, additional: any = {}) {
        const {
            bank_account,
            payment_date_max,
            payment_date_min
        } = Qs.parse(props.location.search.replace(/^\?/, ""))
        props.fetchPayments({ bank_account, payment_date_max, payment_date_min, ...additional })
    }
    componentDidMount() {
        this.fetchPayments(this.props)
    }
    componentWillReceiveProps(nextProps: IPaymentsProps) {
        if (nextProps.location.search !== this.props.location.search) this.fetchPayments(nextProps)
    }
    renderContent() {
        const {
            loading,
            payments,
            total_count,
            i18n
        } = this.props

        return (
            <Table>
                <tbody>
                    { payments.map((st, idx) => (
                        <tr key={idx} className="dropdown">
                            <td><Link to={`/outbound-payments/${st.id}`}>{ st.recipient_name }</Link></td>
                            <td>{ st.payment_date ? d(st.payment_date) : null }</td>
                            <td>{ st.amount }</td>
                            <td><Badge color={paymentStatusStyle(st.status)}>{ paymentStatusText(i18n, st.status) }</Badge></td>
                            <td>
                                <UncontrolledDropdown>
                                    <DropdownToggle color="link" size="xs" style={{ padding: 0 }}>
                                        <Icon name="ellipsis-h" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>{ i18n.gettext("Actions") }</DropdownItem>
                                        <DropdownItem onClick={() => this.resendPayment(st)}>
                                            <Icon name="copy" /> { ' '}
                                            { i18n.gettext("Copy and pay today") }
                                        </DropdownItem>
                                        <DropdownItem onClick={() => this.removePayment(st)}>
                                            <span className="text-danger">
                                                <Icon name="trash" /> { ' '}
                                                { i18n.gettext("Remove") }
                                            </span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
                { loading ? <tbody><tr><td colSpan={3}><LoadingIndicator /></td></tr></tbody> : null }
                { total_count <= payments.length ? null : (
                    <tfoot>
                        <tr>
                            <td colSpan={5}>
                                <Button color="primary" outline onClick={() => this.fetchPayments(this.props, { offset: payments.length })}>{ i18n.gettext('Load more')}</Button>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </Table>
        )
        
    }
    render() {
        const {
            i18n,
            history,
            bank_accounts,
            location: { search }
        } = this.props;

        const {
            bank_account,
            payment_date_min,
            payment_date_max
        } = Qs.parse(search.replace(/^\?/, ""))
        const bankAccountOpts = bank_accounts.map(ba => ({
            value: ba.id, label: bankAccountLabel(ba)
        }))

        const navigate = (params: any) => {
            history.push(`/outbound-payments?${Qs.stringify({
                bank_account,
                payment_date_max,
                payment_date_min,
                ...params
            })}`)
        }

        return (
            <div>
                <h4>{ i18n.gettext('Payments')}</h4>
                <Row>
                    <Col md={8}>
                        <Row>
                            <Col md={12}>
                                <Select
                                    isSearchable
                                    options={bankAccountOpts} 
                                    placeholder={i18n.gettext("Filter by bank account")}
                                    value={bankAccountOpts.find(ba => ba.value === bank_account)}
                                    onChange={(val: ValueType<OptionType>) => navigate({ bank_account: (val as OptionType).value })}
                                    />
                            </Col>
                            <Col md={6}>
                                <br />
                                <DateTimePicker
                                    format="YYYY-MM-DD"
                                    value={payment_date_min ? moment(payment_date_min).toDate(): undefined}
                                    placeholder={i18n.gettext("Date range start")}
                                    onChange={val => navigate({ payment_date_min: moment(val).format("YYYY-MM-DD")})}
                                    time={false}
                                    />
                            </Col>
                            <Col md={6}>
                                <br />
                                <DateTimePicker
                                    format="YYYY-MM-DD"
                                    value={payment_date_max ? moment(payment_date_max).toDate(): undefined}
                                    placeholder={i18n.gettext("Date range end")}
                                    onChange={val => navigate({ payment_date_max: moment(val).format("YYYY-MM-DD")})}
                                    time={false}
                                    />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <div className="text-right">
                            <Button color="primary" onClick={() => history.push('/outbound-payments/new')}>
                                <Icon name="bolt" /> { i18n.gettext('New payment') }
                            </Button>
                        </div>
                    </Col>
                </Row>
                <div>&nbsp;</div>
                { this.renderContent() }
                <Switch>
                    <Route path="/outbound-payments/new" component={NewPaymentModal} />
                    <Route path="/outbound-payments/:id" component={PaymentDetailsModal} />
                </Switch>
            </div>
        )
    }
}

export default connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(withRouter<any, any>(Payments))

function d(str: string) : string {
    return moment(str).format('DD.MM.YYYY');
}

export function paymentStatusText(i18n : any, sts : string) : string {
    if (sts === 'ACTC') return i18n.gettext('Accepted technical validation');
    if (sts === 'ACCP') return i18n.gettext('Accepted content processing');
    if (sts === 'ACSC') return i18n.gettext('Accepted settlement completed');
    if (sts === 'ACSP') return i18n.gettext('Processed');
    if (sts === 'PNDG') return i18n.gettext('Pending for processing');
    if (sts === 'RJCT') return i18n.gettext('Rejected');
    if (sts === '__WAIT') return i18n.gettext('Waiting to be sent to bank')
    return i18n.gettext('Unknown');
  };
  
export function paymentStatusStyle(sts : string) {
    if (sts === 'ACTC') return 'info';
    if (sts === 'ACCP' || sts === 'ACSP' || sts === 'ACSC') return 'success';
    if (sts === 'PNDG') return 'info';
    if (sts === 'RJCT') return 'danger';
    return 'light';
  };

  function bankAccountLabel(ba: IBankAccount) {
      if (!ba.customer_information) return `${ba.iban} / ${ba.bic}`
      return `${ba.iban} / ${ba.bic} / ${ba.customer_information.name}`
  }